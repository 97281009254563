export default {
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
  REFERRAL: 'REFERRAL',
  OTP: 'OTP',
  FORGOTPASSWORD: 'FORGOTPASSWORD',
  RESETPASSWORD: 'RESETPASSWORD',
  LOGOUT: 'LOGOUT',
  TOKEN_LOGIN: 'TOKEN_LOGIN',
  TOKEN_LOGIN_UNAUTHORIZED: 'TOKEN_LOGIN_UNAUTHORIZED',
  CLEAR_RESPONSE: 'CLEAR_RESPONSE',
  GET_SETTINGS: 'GET_SETTINGS',
  GET_PROFILE: 'GET_PROFILE',
  UPDATE_USERNAME: 'UPDATE_USERNAME',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_MOBILENUMBER: 'UPDATE_MOBILENUMBER',
  ADD_BANK: 'ADD_BANK',
  CLEAR_ACCOUNT_RESPONSE: 'CLEAR_ACCOUNT_RESPONSE',
  RESEND_OTP: 'RESEND_OTP',
  UPLOAD_KYC: 'UPLOAD_KYC',
  GET_GAME_TRANSACTIONS: 'GET_GAME_TRANSACTIONS',
  GET_PASSBOOK: 'GET_PASSBOOK',
  GET_TOURNAMENT_TRANSACTION: 'GET_TOURNAMENT_TRANSACTION',
  GET_TABLE_TRANSACTIONS: 'GET_TABLE_TRANSACTIONS',
  CLEAR_GAME_TRANSACTION: 'CLEAR_GAME_TRANSACTION',
  GET_REFERRAL: 'GET_REFERRAL',
  CLEAR_INVITE: 'CLEAR_INVITE',
  GET_BONUS: 'GET_BONUS',
  CLEAR_BONUS: 'CLEAR_BONUS',
  CLEAR_TOURNAMENT_TRANSACTION: 'CLEAR_TOURNAMENT_TRANSACTION',
  CLEAR_PASSBOOK: 'CLEAR_PASSBOOK',
  WITHDRAW: 'WITHDRAW',
  CLEAR_WITHDRAW: 'CLEAR_WITHDRAW',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  GET_PROMOCODE_LIST: 'GET_PROMOCODE_LIST',
  CLEAR_DEPOSIT: 'CLEAR_DEPOSIT',
  ADD_DEPOSIT_CASH: 'ADD_DEPOSIT_CASH',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CLEAR_CHANGE_PASSWORD: 'CLEAR_CHANGE_PASSWORD',
  LOGOUT_PASSWORD_CHANGE: 'LOGOUT_PASSWORD_CHANGE',
  CLEAR_REPORT: 'CLEAR_REPORT',
  REPORT: 'REPORT',
  CLEAR_LOBBY: 'CLEAR_LOBBY',
  GET_LOBBY_TABLE_LIST: 'GET_LOBBY_TABLE_LIST',
  GET_BANNERS: 'GET_BANNERS',
  GET_OFFERS: 'GET_OFFERS',
  CLEAR_OFFERS: 'CLEAR_OFFERS',
  CLEAR_MESSGAE: 'CLEAR_MESSGAE',
  GET_MESSAGE: 'GET_MESSAGE',
  GET_READ_STATUS: 'GET_READ_STATUS',
  GET_JOINED_TABLES: 'GET_JOINED_TABLES',
  CLEAR_TOURNAMENT: 'CLEAR_TOURNAMENT',
  GET_TOURNAMENT_CATEGORY_LIST: 'GET_TOURNAMENT_CATEGORY_LIST',
  GET_TOURNAMENT_LIST: 'GET_TOURNAMENT_LIST',
  GET_TOURNAMENT_DETAILS: 'GET_TOURNAMENT_DETAILS',
  JOIN_TOURNAMENT: 'JOIN_TOURNAMENT',
  WITHDRAW_TOURNAMENT: 'WITHDRAW_TOURNAMENT',
  REFRESH_PRACTICE_CHIPS: 'REFRESH_PRACTICE_CHIPS',
  GET_JOINED_TOURNAMENTS: 'GET_JOINED_TOURNAMENTS',
  JOIN_PRIVATE_TABLE: 'JOIN_PRIVATE_TABLE',
  CREATE_PRIVATE_TABLE: 'CREATE_PRIVATE_TABLE',
  JOIN_TABLE: 'JOIN_TABLE',
  SOCIAL_LOGIN: 'SOCIAL_LOGIN',
  GOOGLE_CLIENT_ID: '628308027631-5aapskibajqtfu7im40ccdanmu6purnf',
  FACEBOOK_APP_ID: '451036936487032',
  ERROR_MSG: "Sorry, we didn't find any account with that Email id/Mobile number",
  DAILY_BONUS: 'DAILY_BONUS',
  BONUS_WIN: 'BONUS_WIN',
  DISMISS_BONUS: 'DISMISS_BONUS',
  TAKE_SEAT: 'TAKE_SEAT',
  GET_TOURNAMENT_QUALIFIERS: 'GET_TOURNAMENT_QUALIFIERS',
  GET_CLUBLIST: 'GET_CLUBLIST',
  CLEAR_CLUB: 'CLEAR_CLUB',
  GET_CLUB_GAMES: 'GET_CLUB_GAMES',
  JOIN_CLUB_TABLE: 'JOIN_CLUB_TABLE',
  JOIN_CLUB: 'JOIN_CLUB',
  GET_CLUB_REQUEST_LIST: 'GET_CLUB_REQUEST_LIST',
  LEAVE_CLUB: 'LEAVE_CLUB',
  CLUB_INFO: 'CLUB_INFO',
  GET_CLUB_BALANCE: 'GET_CLUB_BALANCE',
  CLUB_WITHDRAW: 'CLUB_WITHDRAW',
  GET_CLUB_WITHDRAW_LIST: 'GET_CLUB_WITHDRAW_LIST',
  CANCEL_CLUB_WITHDRAW: 'CANCEL_CLUB_WITHDRAW',
  CLUB_GAME_TRANSACTION: 'CLUB_GAME_TRANSACTION',
  CLUB_TABLE_TRANSACTION: 'CLUB_TABLE_TRANSACTION',
  CLUB_PASSBOOK: 'CLUB_PASSBOOK',
  CLUB_DEPOSIT: 'CLUB_DEPOSIT',
  GET_CLUB_DEPOSIT_LIST: 'GET_CLUB_DEPOSIT_LIST',
  CANCEL_CLUB_DEPOSIT: 'CANCEL_CLUB_DEPOSIT',
  LEAVE_CLUB_FROM_INFO: 'LEAVE_CLUB_FROM_INFO',
  AMAZON_WITHDRAW: 'AMAZON_WITHDRAW',
  MAINTENANCE_MODE: 'MAINTENANCE_MODE',
  CLEAR_MAINTENANCE_MODE: 'CLEAR_MAINTENANCE_MODE',
  FIREBASE_TOKEN: 'FIREBASE_TOKEN',
  UPCOMING: 'UPCOMING',
  REG_STARTED: 'REG_STARTED',
  REGISTERED: 'REGISTERED',
}
