import { React, useState, useEffect } from 'react'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getPassbook } from '../../../actions/passbook'
import Loading from '../../../components/Loading'
import Paginate from '../../../components/Paginate'
import Select from 'react-select'

function passbook() {
  const [passbook, setPassbook] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [firstPage, setFirstPage] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const passbookData = useSelector((state) => state.passbook.passbookData)

  useEffect(() => {
    document.title = 'Rummy24 | My Account'
  }, [])

  useEffect(() => {
    setLoading(true)
    dispatch(getPassbook(pageSize, currentPage, token))
  }, [currentPage, pageSize])

  useEffect(() => {
    if (passbookData?.transactions?.length) {
      setPassbook(passbookData?.transactions)
      setPageCount(Math.ceil(passbookData?.count[0]?.totalData / pageSize))
      setTotalData(passbookData?.count[0]?.totalData / 2)
      setLoading(false)
    } else if (passbookData) {
      setLoading(false)
    }
  }, [passbookData])

  function dateFormat(date) {
    return moment(date).format('MMM DD yyyy hh:mm A')
  }

  const handlePageClick = async (data) => {
    setCurrentPage(data.selected)
  }

  const handlePageSize = (e) => {
    if (Number(e.value) > 10) {
      setCurrentPage(0)
      setFirstPage(true)
    }
    setPageSize(Number(e.value))
    setPageCount(Math.ceil(totalData / e.value))
  }
  // pagination
  const page = [
    { value: 10, label: 'Show 10' },
    { value: 25, label: 'Show 25' },
    { value: 40, label: 'Show 40' },
  ]

  return (
    <>
      {loading && <Loading />}
      <div className="account-cnt sidebar-gap-color white-bg">
        <div className="passbook-section">
          <h3 className="text-center account-title">Passbook</h3>
          {passbook?.length ? (
            <div className="table-cnt table-cnt-box br-6">
              <div className="">
                <table className="common-table table-responsive table">
                  <thead>
                    <tr>
                      <th className="text-center">Reference ID</th>
                      <th className="text-center">Transaction Amount</th>
                      <th className="text-center">Transaction Type</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {passbook.map((pass, i) => (
                      <tr key={i} className="text-center">
                        <td className="text-center">{pass._id}</td>

                        <td className="text-center">&#8377;{pass.nAmount?.toFixed(2) || '0'}</td>
                        <td className="text-center">{pass.eType}</td>
                        <td className="text-center">{pass.eStatus}</td>
                        <td>{pass.dCreatedDate ? dateFormat(pass.dCreatedDate) : 'Not Available'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <div className="pagination-section d-flex justify-content-between">
                                    <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                                    <select
                                        value={pageSize}
                                        onChange={e => handlePageSize(e)}>
                                        {[10, 25, 40].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                <div className="pagination-section d-flex justify-content-between">
                  <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                  <Select
                    options={page}
                    onChange={(e) => handlePageSize(e)}
                    value={page.filter(function (page) {
                      return page.value == pageSize
                    })}
                    classNamePrefix="pagination-select"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="table-cnt table-cnt-box br-6">
              <div className="table-responsive">
                <table className="common-table table">
                  <thead>
                    <tr>
                      <th className="text-center">Tournament ID</th>
                      <th className="text-center">Transaction Amount</th>
                      <th className="text-center">Transaction Type</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="6" className="text-center">
                        Not Available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default connect()(passbook)
