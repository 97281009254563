/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import classnames from 'classnames'
import info from '../../../assets/images/info-icon.svg'
import filter from '../../../assets/images/filter-icon.svg'
import refresh from '../../../assets/images/refresh-icon.svg'
import upArrow from '../../../assets/images/up-arrow.svg'
import downArrow from '../../../assets/images/down-arrow.svg'
import star from '../../../assets/images/star.svg'
import infoIcon from '../../../assets/images/table-info-icon.svg'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getLobbyTableList, joinTable } from '../../../actions/lobby'
import Loading from '../../../components/Loading'

function Cash(props) {
  const [subTab, setSubTab] = useState('8')
  const [tableData, setTableData] = useState([])
  const [filterToggle, setFilterToggle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [maxPlayerFilter, setMaxPlayerFilter] = useState(true)
  const [gameDetails, setGameDetails] = useState('')
  const [showConfirmPlay, setShowConfirmPlay] = useState(false)
  const [tableId, setTableId] = useState('')
  const [twoPlayerChecked, setTwoPlayerChecked] = useState(false)
  const [sixPlayerChecked, setSixPlayerChecked] = useState(false)
  const [showMmodal, setShowMmodal] = useState(props.showMmodal)
  const toggleMaintainanceModal = () => setShowMmodal(!showMmodal)

  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const lobbyTableData = useSelector((state) => state.lobby.data)
  const mainTab = props?.mainTab

  function handlePopup(tableId) {
    if (props.showMmodal) {
      setShowMmodal(true)
    } else {
      setShowConfirmPlay(true)
      setTableId(tableId)
    }
  }

  useEffect(() => {
    if (lobbyTableData) {
      setLoading(false)
      let oldFavIdsArray = []

      if (
        localStorage.getItem('favouritePointCash') !== null ||
        localStorage.getItem('favouritePoolCash') !== null ||
        localStorage.getItem('favouriteDealCash') !== null
      ) {
        if (mainTab === '2' && subTab === '8') {
          const data = JSON.parse(localStorage.getItem('favouritePointCash') || '[]')
          oldFavIdsArray = handleOldFavourite(data)
        }
        if (mainTab === '2' && subTab === '9') {
          const data = JSON.parse(localStorage.getItem('favouritePoolCash') || '[]')
          oldFavIdsArray = handleOldFavourite(data)
        }
        if (mainTab === '2' && subTab === '10') {
          const data = JSON.parse(localStorage.getItem('favouriteDealCash') || '[]')
          oldFavIdsArray = handleOldFavourite(data)
        }
        setTableData(oldFavIdsArray)
      } else {
        setTableData(lobbyTableData)
      }
    }
  }, [lobbyTableData])

  function handleOldFavourite(data) {
    const oldFavData = []
    lobbyTableData.forEach((table) => {
      if (data.includes(table._id.toString())) {
        oldFavData.push({ ...table, favourite: true })
        return
      }
      oldFavData.push({ ...table, favourite: false })
    })

    oldFavData.forEach(function (item, i) {
      if (item?.favourite) {
        oldFavData.splice(i, 1)
        oldFavData.unshift(item)
      }
    })
    return oldFavData
  }

  const toggleSubTab = (tab) => {
    if (subTab !== tab) setSubTab(tab)
  }

  useEffect(() => {
    if (mainTab === '2' && subTab === '8') {
      setLoading(true)
      dispatch(getLobbyTableList('point', 'cash', token))
      setFilterToggle(false)
      setGameDetails('Play for points which have a pre-decided value. One winner wins the complete prize pool at the end of each game.')
    }
    if (mainTab === '2' && subTab === '9') {
      setLoading(true)
      dispatch(getLobbyTableList('pool', 'cash', token))
      setFilterToggle(false)
      setGameDetails(
        'Play until score reaches the maximum limit [101 or 201]. Winner of a deal gets 0 points and the rest accumulate points which are added to their score. A fixed entry fee forms the prize pool.'
      )
    }
    if (mainTab === '2' && subTab === '10') {
      setLoading(true)
      dispatch(getLobbyTableList('deal', 'cash', token))
      setFilterToggle(false)
      setGameDetails(
        'Play with chips that are allocated at the beginning of the deal for pre-decided number of deals. One winner wins all the chips at the end of each deal. At the end of pre-decided number of deals, player with  maximum number of chips is selected as the winner for the game.'
      )
    }
  }, [mainTab, subTab])

  function handleFavouriteCash(id, type) {
    const matchedTables = tableData.map((t) => {
      if (t._id === id) {
        if (t?.favourite) {
          return { ...t, favourite: false }
        } else {
          return { ...t, favourite: true }
        }
      }
      return { ...t }
    })
    matchedTables.forEach(function (item, i) {
      if (item?.favourite) {
        matchedTables.splice(i, 1)
        matchedTables.unshift(item)
      }
    })

    const favouriteIds = matchedTables.filter((res) => res.favourite).map((ele) => ele._id)

    if (type == 'pointCash') {
      localStorage.setItem('favouritePointCash', JSON.stringify(favouriteIds))
    }
    if (type == 'poolCash') {
      localStorage.setItem('favouritePoolCash', JSON.stringify(favouriteIds))
    }
    if (type == 'dealCash') {
      localStorage.setItem('favouriteDealCash', JSON.stringify(favouriteIds))
    }
    setTableData(matchedTables)
  }

  function handleAscending(sortType) {
    const tableObj = [...tableData]
    const ascendingSorted = tableObj.sort((a, b) => a[sortType] - b[sortType])
    setTableData(ascendingSorted)
  }
  function handleDescending(sortType) {
    const tableObj = [...tableData]
    const descendingSorted = tableObj.sort((a, b) => b[sortType] - a[sortType])
    setTableData(descendingSorted)
  }

  function handleFilter(e, type) {
    setFilterToggle(false)
    setMaxPlayerFilter((maxPlayerFilter) => !maxPlayerFilter)
    switch (type) {
      case 'two':
        setTwoPlayerChecked(!twoPlayerChecked)
        break
      case 'six':
        setSixPlayerChecked(!sixPlayerChecked)
        break
    }
    if (maxPlayerFilter) {
      const tableObj = [...tableData]
      const result = tableObj.filter((tb) => (e.target.checked ? tb.nMaxPlayer == e.target.value : tb.nMaxPlayer != e.target.value))
      setTableData(result)
    } else {
      if (mainTab === '2' && subTab === '8') {
        if (localStorage.getItem('favouritePointCash') === null) {
          dispatch(getLobbyTableList('point', 'cash', token))
        } else {
          const data = JSON.parse(localStorage.getItem('favouritePointCash') || '[]')
          const oldFilter = handleOldFilter(data)
          setTableData(oldFilter)
        }
      }
      if (mainTab === '2' && subTab === '9') {
        if (localStorage.getItem('favouritePoolCash') === null) {
          dispatch(getLobbyTableList('pool', 'cash', token))
        } else {
          const data = JSON.parse(localStorage.getItem('favouritePoolCash') || '[]')
          const oldFilter = handleOldFilter(data)
          setTableData(oldFilter)
        }
      }
      if (mainTab === '2' && subTab === '10') {
        if (localStorage.getItem('favouriteDealCash') === null) {
          dispatch(getLobbyTableList('deal', 'cash', token))
        } else {
          const data = JSON.parse(localStorage.getItem('favouriteDealCash') || '[]')
          const oldFilter = handleOldFilter(data)
          setTableData(oldFilter)
        }
      }
    }
  }

  function handleOldFilter(data) {
    const newData = []
    lobbyTableData.forEach((td) => {
      if (data.includes(td._id.toString())) {
        newData.push({ ...td, favourite: true })
        return
      }
      newData.push({ ...td, favourite: false })
    })
    return newData
  }

  function resetFilter() {
    setFilterToggle(false)
    setMaxPlayerFilter(true)
    setTwoPlayerChecked(false)
    setSixPlayerChecked(false)
    if (mainTab === '2' && subTab === '8') {
      if (localStorage.getItem('favouritePointCash') === null) {
        dispatch(getLobbyTableList('point', 'cash', token))
      } else {
        const data = JSON.parse(localStorage.getItem('favouritePointCash') || '[]')
        const resetData = handleOldFilter(data)
        setTableData(resetData)
      }
    }
    if (mainTab === '2' && subTab === '9') {
      if (localStorage.getItem('favouritePoolCash') === null) {
        dispatch(getLobbyTableList('pool', 'cash', token))
      } else {
        const data = JSON.parse(localStorage.getItem('favouritePoolCash') || '[]')
        const resetData = handleOldFilter(data)
        setTableData(resetData)
      }
    }
    if (mainTab === '2' && subTab === '10') {
      if (localStorage.getItem('favouriteDealCash') === null) {
        dispatch(getLobbyTableList('deal', 'cash', token))
      } else {
        const data = JSON.parse(localStorage.getItem('favouriteDealCash') || '[]')
        const resetData = handleOldFilter(data)
        setTableData(resetData)
      }
    }
  }

  function playCashRummy() {
    dispatch(joinTable(tableId, token))
    setShowConfirmPlay(false)
    setTableId('')
  }

  return (
    <div>
      {loading && <Loading />}
      <div className="table-cnt table-cnt-box">
        <div className="d-flex justify-content-between align-items-center">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: subTab === '8' })}
                onClick={() => {
                  toggleSubTab('8')
                }}
              >
                Points Rummy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: subTab === '9' })}
                onClick={() => {
                  toggleSubTab('9')
                }}
              >
                Pools Rummy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: subTab === '10' })}
                onClick={() => {
                  toggleSubTab('10')
                }}
              >
                Deals Rummy
              </NavLink>
            </NavItem>
          </Nav>
          <div className="filter-section d-flex align-items-center">
            <Button type="button" id="infoTooltipCash">
              <img src={info} alt="info" />
            </Button>
            <UncontrolledTooltip autohide={false} placement="bottom" target="infoTooltipCash" className="pop-desc">
              {gameDetails}
            </UncontrolledTooltip>
            <button
              type="button"
              onClick={() => setFilterToggle((filterToggle) => !filterToggle)}
              className={'btn btn-secondary filter-btn'}
            >
              <img src={filter} alt="filter" />
            </button>
          </div>
        </div>
        <div className={filterToggle ? 'filter-detail show' : 'filter-detail hide'}>
          <div className="d-flex align-items-center">
            <ul className="filter-list p-0 d-flex align-items-center">
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle caret>Max Players</DropdownToggle>
                  <DropdownMenu>
                    <div>
                      <label htmlFor="chkbox7" className="d-flex align-items-center">
                        <div className="custom-checkbox">
                          <input
                            readOnly
                            id="chkbox7"
                            checked={twoPlayerChecked}
                            type="checkbox"
                            value="2"
                            onClick={(e) => handleFilter(e, 'two')}
                          />
                          <span></span>
                        </div>
                        2 Players
                      </label>
                    </div>
                    <div>
                      <label htmlFor="chkbox8" className="d-flex align-items-center">
                        <div className="custom-checkbox">
                          <input
                            readOnly
                            id="chkbox8"
                            checked={sixPlayerChecked}
                            type="checkbox"
                            value="6"
                            onClick={(e) => handleFilter(e, 'six')}
                          />
                          <span></span>
                        </div>
                        6 Players
                      </label>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
              <li>
                <button type="button" className="ref-btn border-0 bg-transparent" onClick={resetFilter}>
                  <img src={refresh} alt="refresh" />
                  Reset
                </button>
              </li>
            </ul>
          </div>
        </div>
        <TabContent activeTab={subTab}>
          <TabPane tabId="8">
            <Table responsive className="common-table">
              <thead>
                <tr>
                  <th className="text-center">name</th>
                  <th className="text-center">decks</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      max players
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMaxPlayer')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMaxPlayer')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      point value
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nPoint')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nPoint')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      min entry
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMinEntry')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMinEntry')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length ? (
                  tableData.map((pointCash, i) => (
                    <tr key={i}>
                      <td>
                        <div className="favourite d-flex ">
                          <button
                            type="button"
                            onClick={() => handleFavouriteCash(pointCash._id, 'pointCash')}
                            className={pointCash?.favourite ? 'active border-0 bg-transparent' : 'border-0 bg-transparent'}
                          >
                            <img src={star} alt="star" />
                          </button>
                          <span>{pointCash?.sName}</span>
                        </div>
                      </td>
                      <td className="text-center">2</td>
                      <td className="text-center">
                        {pointCash?.nMaxPlayer == 2 ? (
                          <div className="gametable-icon-2">&nbsp;</div>
                        ) : (
                          <div className="gametable-icon-6">&nbsp;</div>
                        )}
                      </td>
                      <td className="text-center">{pointCash?.nPoint?.toFixed(2)}</td>
                      <td className="text-center">
                        {pointCash?.nMinEntry} &nbsp;
                        {pointCash?.oBonus?.nValue > 0 && (
                          <>
                            <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0">
                              <img src={infoIcon} alt="Info" className="table-info-ico" />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                              Bonus utilisation for this table is: {pointCash?.oBonus?.nValue}%
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                      <td>
                        <div className="text-end">
                          <button type="button" onClick={() => handlePopup(pointCash?._id)} className="common-btn">
                            Play Now
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        There are no Tables to join.
                        <br /> Please come back later.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </TabPane>
          <TabPane tabId="9">
            <Table responsive className="common-table">
              <thead>
                <tr>
                  <th className="text-center">name</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Type
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nPool')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nPool')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      max players
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMaxPlayer')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMaxPlayer')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Entry Fee
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nEntryFee')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nEntryFee')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Prize
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('aWinningAmount[0]')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('aWinningAmount[0]')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length ? (
                  tableData.map((poolCash, i) => (
                    <tr key={i}>
                      <td>
                        <div className="favourite d-flex ">
                          <button
                            onClick={() => handleFavouriteCash(poolCash._id, 'poolCash')}
                            type="button"
                            className={poolCash?.favourite ? 'active border-0 bg-transparent' : 'border-0 bg-transparent'}
                          >
                            <img src={star} alt="star" />
                          </button>
                          <span>{poolCash?.sName}</span>
                        </div>
                      </td>
                      <td className="text-center">{poolCash?.nPool}</td>
                      <td className="text-center">
                        {poolCash?.nMaxPlayer == 2 ? (
                          <div className="gametable-icon-2">&nbsp;</div>
                        ) : (
                          <div className="gametable-icon-6">&nbsp;</div>
                        )}
                      </td>
                      <td className="text-center">&#8377;{poolCash?.nEntryFee?.toFixed(2)}</td>
                      <td className="text-center">
                        &#8377;
                        {poolCash?.aWinningAmount?.length == 2
                          ? poolCash?.aWinningAmount[0] + poolCash?.aWinningAmount[1]
                          : poolCash?.aWinningAmount[0]}{' '}
                        &nbsp;
                        {poolCash?.aWinningAmount?.length == 2 && (
                          <>
                            <Button id={`pointCashBtnPrize-${i}`} type="button" className="p-0 bg-transparent border-0">
                              <p>?</p>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={`pointCashBtnPrize-${i}`} className="pop-desc bottom">
                              1st WIN: &#8377;{poolCash?.aWinningAmount[0]} <br /> 2nd WIN: &#8377;
                              {poolCash?.aWinningAmount[1]}
                            </UncontrolledTooltip>
                          </>
                        )}
                        &nbsp;&nbsp;
                        {poolCash?.oBonus?.nValue > 0 && (
                          <>
                            <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0">
                              <img src={infoIcon} alt="Info" className="table-info-ico" />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                              Bonus utilisation for this table is: {poolCash?.oBonus?.nValue}%
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                      <td>
                        <div className="text-end">
                          <button type="button" onClick={() => handlePopup(poolCash?._id)} className="common-btn">
                            Play Now
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        There are no Tables to join.
                        <br /> Please come back later.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </TabPane>
          <TabPane tabId="10">
            <Table responsive className="common-table">
              <thead>
                <tr>
                  <th className="text-center">name</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Deals
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nDeal')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nDeal')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      max players
                      <div className="order-filter">
                        <button type="button" onClick={() => handleAscending('nMaxPlayer')} className="border-0 bg-transparent">
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" onClick={() => handleDescending('nMaxPlayer')} className="border-0 bg-transparent">
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Entry Fee
                      <div className="order-filter">
                        <button type="button" onClick={() => handleAscending('nEntryFee')} className="border-0 bg-transparent">
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" onClick={() => handleDescending('nEntryFee')} className="border-0 bg-transparent">
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Prize
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('aWinningAmount[0]')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('aWinningAmount[0]')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length ? (
                  tableData.map((dealCash, i) => (
                    <tr key={i}>
                      <td>
                        <div className="favourite d-flex ">
                          <button
                            type="button"
                            onClick={() => handleFavouriteCash(dealCash._id, 'dealCash')}
                            className={dealCash?.favourite ? 'active border-0 bg-transparent' : 'border-0 bg-transparent'}
                          >
                            <img src={star} alt="star" />
                          </button>
                          <span>{dealCash?.sName}</span>
                        </div>
                      </td>
                      <td className="text-center">{dealCash?.nDeal}</td>
                      <td className="text-center">
                        {dealCash?.nMaxPlayer == 2 ? (
                          <div className="gametable-icon-2">&nbsp;</div>
                        ) : (
                          <div className="gametable-icon-6">&nbsp;</div>
                        )}
                      </td>
                      <td className="text-center">&#8377;{dealCash?.nEntryFee}</td>
                      <td className="text-center">
                        &#8377;
                        {dealCash?.aWinningAmount?.length == 2
                          ? dealCash?.aWinningAmount[0] + dealCash?.aWinningAmount[1]
                          : dealCash?.aWinningAmount[0]}{' '}
                        &nbsp;
                        {dealCash?.aWinningAmount?.length == 2 && (
                          <>
                            <Button id={`pointCashBtnPrize-${i}`} type="button" className="p-0 bg-transparent border-0">
                              <p>?</p>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={`pointCashBtnPrize-${i}`} className="pop-desc bottom">
                              1st WIN: &#8377;{dealCash?.aWinningAmount[0]} <br /> 2nd WIN: &#8377;
                              {dealCash?.aWinningAmount[1]}
                            </UncontrolledTooltip>
                          </>
                        )}
                        &nbsp;&nbsp;
                        {dealCash?.oBonus?.nValue > 0 && (
                          <>
                            <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0">
                              <img src={infoIcon} alt="Info" className="table-info-ico" />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                              Bonus utilisation for this table is: {dealCash?.oBonus?.nValue}%
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                      <td>
                        <div className="text-end">
                          <button type="button" onClick={() => handlePopup(dealCash?._id)} className="common-btn">
                            Play Now
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        There are no Tables to join.
                        <br /> Please come back later.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </TabPane>
        </TabContent>
        <div className={showConfirmPlay ? 'edit-auth-dtl active' : 'edit-auth-dtl'}>
          <div>
            <p>
              <strong>Are you sure you want to play this game?</strong>
            </p>
            <div className="d-flex side-btn justify-content-center">
              <input type="button" className="common-btn yellow-btn small-btn" value="Play" onClick={playCashRummy} />
              <input type="button" className="common-btn yellow-btn small-btn" value="Cancel" onClick={() => setShowConfirmPlay(false)} />
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showMmodal} toggle={toggleMaintainanceModal} className="join-modal">
        <ModalHeader toggle={toggleMaintainanceModal} className="text-center">
          Can&apos;t play
        </ModalHeader>
        <ModalBody>
          <div className="m-3 ">
            <div className="white-txt text-center">
              <span className="white-txt text-center">Game is Under Maintenance, You can&apos;t play game right now</span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default connect()(Cash)
