import constants from '../constants'

export default (state = {}, action) => {
    switch (action.type) {
        case constants.GET_TOURNAMENT_CATEGORY_LIST:
            return {
                ...state,
                tournamentCategoryListData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.GET_TOURNAMENT_LIST:
            return {
                ...state,
                tournamentListData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.UPCOMING:
            return {
                ...state,
                upcomingTournamentListData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.REG_STARTED:
            return {
                ...state,
                regStartedTournamentListData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.REGISTERED:
            return {
                ...state,
                registredTournamentListData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.GET_TOURNAMENT_DETAILS:
            return {
                ...state,
                tournamentDetailsData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.JOIN_TOURNAMENT:
            return {
                ...state,
                joinResStatus: action.payload.resStatus,
                joinResMessage: action.payload.resMessage
            }
        case constants.WITHDRAW_TOURNAMENT:
            return {
                ...state,
                withdrawResStatus: action.payload.resStatus,
                joinResMessage: action.payload.resMessage
            }
        case constants.TAKE_SEAT:
            return {
                ...state,
                takeSeatData: action.payload.data,
            }
        case constants.CLEAR_TOURNAMENT:
            return {
                tournamentCategoryListData: null,
                upcomingTournamentListData: null,
                regStartedTournamentListData: null,
                registredTournamentListData: null,
                tournamentListData: null,
                tournamentDetailsData: null,
                resStatus: null,
                joinResStatus: null,
                withdrawResStatus: null,
                joinResMessage: '',
                resMessage: '',
                takeSeatData: null
            }
        default:
            return state
    }
}