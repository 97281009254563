import axios from 'axios'
import config from './config'
import { unAuthorized } from './helper'
import constants from './constants'
// import Toast from './components/Toast'

const instance = axios.create({
  baseURL: config.API_URL,
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response && (error?.response?.status === 401 || error?.response?.data?.message === constants.ERROR_MSG)) {
      localStorage.setItem('logout', 'toast')
      unAuthorized()
      localStorage.removeItem('logout', 'toast')
      // Toast()
    }
    return Promise.reject(error)
  }
)

export default instance
