import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import LobbyContent from './LobbyDetails'

function Lobby (props) {
    return (
        <Fragment>
            <Header {...props} />
            <LobbyContent {...props} />
            <Footer />
        </Fragment>
    )
}
export default connect()(Lobby)