import axios from '../axios'

import constants from '../constants'
import config from '../config'

const errMsg = 'Server is unavailable.'

export const getPromocodeList = (amount, token) => (dispatch) => {
  dispatch({ type: constants.CLEAR_DEPOSIT })
  axios
    .post(
      config.API_URL_V2 + '/promocode/filter',
      {
        nMinimumAmount: amount,
        nMaximumAmount: amount,
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => {
      dispatch({
        type: constants.GET_PROMOCODE_LIST,
        payload: {
          data: response.data.data,
          resStatus: true,
          resMessage: response.data.message,
        },
      })
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_PROMOCODE_LIST,
        payload: {
          resStatus: false,
          resMessage: error.response ? error.response.data.message : errMsg,
        },
      })
    })
}

export const depositCash = (nAmount, iPromoCodeId, token) => (dispatch) => {
  dispatch({ type: constants.CLEAR_DEPOSIT })
  axios
    .post(
      config.API_URL_V2 + '/transaction/buy',
      {
        ...(nAmount ? { nAmount: nAmount } : {}),
        ...(iPromoCodeId?.length ? { iPromoCodeId: iPromoCodeId } : {}),
        sPlatform: 'web',
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => {
      dispatch({
        type: constants.ADD_DEPOSIT_CASH,
        payload: {
          data: response.data.data,
          resStatus: true,
          resMessage: response.data.message,
        },
      })
    })
    .catch((error) => {
      dispatch({
        type: constants.ADD_DEPOSIT_CASH,
        payload: {
          resStatus: false,
          resMessage: error.response ? error.response.data.message : errMsg,
        },
      })
    })
}
