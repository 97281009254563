import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Countdown from 'react-countdown'
import { useDispatch } from 'react-redux'
import { getSettings } from '../actions/account'

export default function TimerBanner({ ttl, ttl2, showScheduleMode, showRunningMode, setShowRunningMode }) {
  const dispatch = useDispatch()
  const [showScheduleModeBanner, setShowScheduleModeBanner] = useState(showScheduleMode)
  const [showRunningModeBanner, setRunninguleModeBanner] = useState(showRunningMode)

  const scheduledTime = useMemo(() => Date.now() + ttl, [ttl])
  const runningTime = useMemo(() => Date.now() + ttl2, [ttl2])

  const maintainance = () => {
    setShowRunningMode(true)
    setShowScheduleModeBanner(false)
    setRunninguleModeBanner(true)
    dispatch(getSettings())
  }

  const overMaintenance = () => {
    setShowRunningMode(false)
    setShowScheduleModeBanner(false)
    setRunninguleModeBanner(false)
  }
  return (
    <>
      {showScheduleModeBanner && (
        <div className="container mb-3 blue-bg pt-2 pb-2 d-flex flex-column">
          <span className="white-txt text-center color-yellow">
            The Game will go under maintenance by given time
            <Countdown className="p-1" date={scheduledTime} daysInHours={true} onComplete={maintainance}></Countdown>
          </span>
          <span className="white-txt text-center color-yellow ps-2">Make sure you leave any joind table before it.</span>
        </div>
      )}
      {showRunningModeBanner && (
        <div className="container mb-3 blue-bg pt-2 pb-2 d-flex flex-column">
          <span className="text-center color-yellow">
            Maintainance is running and over after
            <Countdown className="p-1" date={runningTime} daysInHours={true} onComplete={overMaintenance}></Countdown>
          </span>
        </div>
      )}
    </>
  )
}

TimerBanner.propTypes = {
  ttl: PropTypes.number,
  ttl2: PropTypes.number,
  showScheduleMode: PropTypes.bool,
  showRunningMode: PropTypes.bool,
  setShowRunningMode: PropTypes.bool,
}
