/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Input,
  Table,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import classnames from 'classnames'
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import info from '../../../assets/images/info-icon.svg'
import filter from '../../../assets/images/filter-icon.svg'
import refresh from '../../../assets/images/refresh-icon.svg'
import upArrow from '../../../assets/images/up-arrow.svg'
import downArrow from '../../../assets/images/down-arrow.svg'
import infoIcon from '../../../assets/images/table-info-icon.svg'
import menu from '../../../assets/images/menu-bars.svg'
import Loading from '../../../components/Loading'
import {
  cancelClubDeposit,
  cancelClubWithdraw,
  clubDeposit,
  clubWithdraw,
  getClubBalance,
  getClubDepositList,
  getClubGames,
  getClubGameTransaction,
  getClubInfo,
  getClubList,
  getClubPassbook,
  getClubRequestList,
  getClubTableTransaction,
  getClubWithdrawList,
  joinClubRequest,
  joinClubTable,
  leaveClub,
  leaveClubFromInfo,
} from '../../../actions/club'
import BackArrow from '../../../assets/images/table-left-arrow 2.png'
import deposit from '../../../assets/images/deposit1.svg'
import whiteArrow from '../../../assets/images/right-arrow.svg'
import gameTrans from '../../../assets/images/game-transaction.svg'
import passbook from '../../../assets/images/passbook.svg'
import withdraw from '../../../assets/images/withdraw.svg'
import exitIcon from '../../../assets/images/exit.svg'
import { verifyLength } from '../../../helper'

function Club(props) {
  const [subTab, setSubTab] = useState('8')
  const [tableData, setTableData] = useState([])
  const [filterToggle, setFilterToggle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [maxPlayerFilter, setMaxPlayerFilter] = useState(true)
  const [gameDetails, setGameDetails] = useState('')
  const [tableId, setTableId] = useState('')
  const [twoPlayerChecked, setTwoPlayerChecked] = useState(false)
  const [sixPlayerChecked, setSixPlayerChecked] = useState(false)
  const [switchToClubDetails, setSwitchToClubDetails] = useState(false)
  const [clubList, setClubList] = useState([])
  const [clubName, setClubName] = useState('')
  const [clubId, setClubId] = useState('')
  const [clubCode, setClubCode] = useState('')
  const [clubRequestList, setClubRequestList] = useState([])
  const [clubDetailModal, setclubDetailModal] = useState(false)
  const [clubInfo, setClubInfo] = useState([])
  const [clubBalance, setClubBalance] = useState(0)
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [submitWithdrawDisable, setSubmitWithdrawDisable] = useState(false)
  const [clubWithdrawList, setClubWithdrawList] = useState([])
  const [clubGameTransaction, setClubGameTransaction] = useState([])
  const [clubPassbookTransaction, setClubPassbookTransaction] = useState([])
  const [switchToTable, setSwitchToTable] = useState(false)
  const [clubTableTransaction, setClubTableTransaction] = useState([])
  const [minDepositAmount, setMinDepositAmount] = useState(0)
  const [depositAmount, setDepositAmount] = useState('')
  const [disableDeposit, setDisableDeposit] = useState(false)
  const [clubDepositList, setClubDepositList] = useState([])
  const [joinClub, setjoinClub] = useState(false)
  const [leaveClubModal, setleaveClubModal] = useState(false)
  const [clubInfoModal, setclubInfoModal] = useState(false)
  const [passbookModal, setpassbookModal] = useState(false)
  const [withdrawModal, setwithdrawModal] = useState(false)
  const [transactionModal, settransactionModal] = useState(false)
  const [depositModal, setdepositModal] = useState(false)
  const [showMmodal, setShowMmodal] = useState(false)
  const toggleMaintainanceModal = () => setShowMmodal(!showMmodal)
  const [showConfirmPlay, setShowConfirmPlay] = useState(false)

  const mainTab = props?.mainTab
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const clubListData = useSelector((state) => state.club.data)
  const clubGameData = useSelector((state) => state.club.clubGameData)
  const clubRequestListData = useSelector((state) => state.club.clubRequestListData)
  const leaveClubStatus = useSelector((state) => state.club.resStatusLeaveClub)
  const leaveClubFromInfoStatus = useSelector((state) => state.club.resStatusLeaveClubFromInfo)
  const clubJoinresMessage = useSelector((state) => state.club.resMessageJoinClub)
  const clubJoinStatus = useSelector((state) => state.club.resStatusJoinClub)
  const clubInfoData = useSelector((state) => state.club.clubInfoData)
  const clubBalanceData = useSelector((state) => state.club.clubBalanceData)
  const clubWithdrawStatus = useSelector((state) => state.club.resStatusWithdraw)
  const clubWithdrawListData = useSelector((state) => state.club.clubWithdrawList)
  const clubDepositListData = useSelector((state) => state.club.clubDepositList)
  const cancelClubWithdrawStatus = useSelector((state) => state.club.resStatusCancelClubWithdraw)
  const clubGameTransactionData = useSelector((state) => state.club.clubGameTransaction)
  const clubPassbookTransactionData = useSelector((state) => state.club.clubPassbookTransaction)
  const clubTableTransactionData = useSelector((state) => state.club.clubTableTransaction)
  const clubDepositStatus = useSelector((state) => state.club.resStatusClubDeposit)
  const cancelClubDepositStatus = useSelector((state) => state.club.resStatusCancelClubDeposit)
  const gameSettingsData = useSelector((state) => state.account.data?.oMaintenance)
  const socketModeData = useSelector((state) => state.lobby.mModeData)

  const previousProps = useRef({
    clubJoinresMessage,
    clubJoinStatus,
    clubBalanceData,
    clubWithdrawStatus,
    cancelClubDepositStatus,
    leaveClubFromInfoStatus,
  }).current

  useEffect(() => {
    dispatch(getClubList(token))
  }, [])

  useEffect(() => {
    if (switchToClubDetails === true) {
      if (mainTab === '4' && subTab === '8') {
        setLoading(true)
        dispatch(getClubGames(clubId, 'point', token))
        setFilterToggle(false)
        setGameDetails('Play for points which have a pre-decided value. One winner wins the complete prize pool at the end of each game.')
      }
      if (mainTab === '4' && subTab === '9') {
        setLoading(true)
        dispatch(getClubGames(clubId, 'pool', token))
        setFilterToggle(false)
        setGameDetails(
          'Play until score reaches the maximum limit [101 or 201]. Winner of a deal gets 0 points and the rest accumulate points which are added to their score. A fixed entry fee forms the prize pool.'
        )
      }
      if (mainTab === '4' && subTab === '10') {
        setLoading(true)
        dispatch(getClubGames(clubId, 'deal', token))
        setFilterToggle(false)
        setGameDetails(
          'Play with chips that are allocated at the beginning of the deal for pre-decided number of deals. One winner wins all the chips at the end of each deal. At the end of pre-decided number of deals, player with  maximum number of chips is selected as the winner for the game.'
        )
      }
    }
  }, [switchToClubDetails, mainTab, subTab])

  useEffect(() => {
    if (previousProps.clubJoinresMessage !== clubJoinresMessage) {
      if (clubJoinresMessage?.length) {
        setLoading(false)
        if (clubJoinStatus === true) {
          dispatch(getClubRequestList(token))
          setClubCode('')
        }
        toast(
          <div>
            <div>{clubJoinresMessage}</div>
          </div>
        )
      }
    }
    return () => {
      previousProps.clubJoinresMessage = clubJoinresMessage
    }
  }, [clubJoinresMessage])

  useEffect(() => {
    if (previousProps.clubWithdrawStatus !== clubWithdrawStatus) {
      if (clubWithdrawStatus === false) {
        setLoading(false)
        setWithdrawAmount('')
        setSubmitWithdrawDisable(false)
      }
      if (clubWithdrawStatus === true) {
        setLoading(false)
        setWithdrawAmount('')
        setSubmitWithdrawDisable(false)
        dispatch(getClubWithdrawList(clubId, token))
      }
    }
    return () => {
      previousProps.clubWithdrawStatus = clubWithdrawStatus
    }
  }, [clubWithdrawStatus])

  useEffect(() => {
    if (previousProps.clubDepositStatus !== clubDepositStatus) {
      if (clubDepositStatus === true) {
        setLoading(false)
        setDepositAmount('')
        setMinDepositAmount(0)
        setDisableDeposit(false)
        dispatch(getClubDepositList(clubId, token))
      }
      if (clubDepositStatus === false) {
        setLoading(false)
        setDepositAmount('')
        setMinDepositAmount(0)
        setDisableDeposit(false)
      }
    }
    return () => {
      previousProps.clubDepositStatus = clubDepositStatus
    }
  }, [clubDepositStatus])

  useEffect(() => {
    if (previousProps.leaveClubStatus !== leaveClubStatus) {
      if (leaveClubStatus === false) {
        setLoading(false)
      }
      if (leaveClubStatus === true) {
        setLoading(false)
        dispatch(getClubRequestList(token))
      }
    }
    return () => {
      previousProps.leaveClubStatus = leaveClubStatus
    }
  }, [leaveClubStatus])

  useEffect(() => {
    if (previousProps.leaveClubFromInfoStatus !== leaveClubFromInfoStatus) {
      if (leaveClubFromInfoStatus === true) {
        setLoading(false)
        setleaveClubModal(false)
        setclubDetailModal(false)
        setSwitchToClubDetails(false)
        dispatch(getClubList(token))
      }
      if (leaveClubFromInfoStatus === false) {
        setLoading(false)
        setleaveClubModal(false)
      }
    }
    return () => {
      previousProps.leaveClubFromInfoStatus = leaveClubFromInfoStatus
    }
  }, [leaveClubFromInfoStatus])

  useEffect(() => {
    if (previousProps.cancelClubWithdrawStatus !== cancelClubWithdrawStatus) {
      if (cancelClubWithdrawStatus === false) {
        setLoading(false)
      }
      if (cancelClubWithdrawStatus === true) {
        setLoading(false)
        dispatch(getClubWithdrawList(clubId, token))
      }
    }
    return () => {
      previousProps.cancelClubWithdrawStatus = cancelClubWithdrawStatus
    }
  }, [cancelClubWithdrawStatus])

  useEffect(() => {
    if (previousProps.cancelClubDepositStatus !== cancelClubDepositStatus) {
      if (cancelClubDepositStatus === false) {
        setLoading(false)
      }
      if (cancelClubDepositStatus === true) {
        setLoading(false)
        dispatch(getClubDepositList(clubId, token))
      }
    }
    return () => {
      previousProps.cancelClubDepositStatus = cancelClubDepositStatus
    }
  }, [cancelClubDepositStatus])

  useEffect(() => {
    if (clubListData?.length) {
      setLoading(false)
      setClubList(clubListData?.filter((data) => data?.eState === 'approved'))
    }
  }, [clubListData])

  useEffect(() => {
    if (clubWithdrawListData) {
      setClubWithdrawList(clubWithdrawListData?.transactions)
    }
  }, [clubWithdrawListData])

  useEffect(() => {
    if (clubDepositListData) {
      setClubDepositList(clubDepositListData?.transactions)
    }
  }, [clubDepositListData])

  useEffect(() => {
    if (clubInfoData) {
      setClubInfo(clubInfoData)
    }
  }, [clubInfoData])

  useEffect(() => {
    if (clubGameTransactionData) {
      setClubGameTransaction(clubGameTransactionData)
    }
  }, [clubGameTransactionData])

  useEffect(() => {
    if (clubPassbookTransactionData) {
      setClubPassbookTransaction(clubPassbookTransactionData?.transactions)
    }
  }, [clubPassbookTransactionData])

  useEffect(() => {
    if (clubTableTransactionData) {
      setLoading(false)
      setClubTableTransaction(clubTableTransactionData?.transactions)
    }
  }, [clubTableTransactionData])

  useEffect(() => {
    if (clubGameData) {
      setLoading(false)
      setTableData(clubGameData)
    }
  }, [clubGameData])

  useEffect(() => {
    if (clubBalanceData) {
      setClubBalance(clubBalanceData)
    }
  }, [clubGameData])

  useEffect(() => {
    if (clubRequestListData) {
      setLoading(false)
      setClubRequestList(clubRequestListData)
    }
  }, [clubRequestListData])

  function clubDetailModalToggle() {
    setclubDetailModal(!clubDetailModal)
    if (clubDetailModal === false) {
      dispatch(getClubInfo(clubId, token))
      dispatch(getClubWithdrawList(clubId, token))
      dispatch(getClubGameTransaction(clubId, token))
      dispatch(getClubPassbook(clubId, token))
      dispatch(getClubDepositList(clubId, token))
    }
  }

  function handleClubDetailSwitch(cName, cId) {
    setSwitchToClubDetails(true)
    setClubName(cName)
    setClubId(cId)
    dispatch(getClubBalance(cId, token))
  }

  function handleAscending(sortType) {
    if (sortType === 'aWinningAmount') {
      const tableObj = [...tableData]
      const ascendingSorted = tableObj.sort((a, b) => a?.aWinningAmount[0] - b?.aWinningAmount[0])
      setTableData(ascendingSorted)
    } else {
      const tableObj = [...tableData]
      const ascendingSorted = tableObj.sort((a, b) => a[sortType] - b[sortType])
      setTableData(ascendingSorted)
    }
  }
  function handleDescending(sortType) {
    if (sortType === 'aWinningAmount') {
      const tableObj = [...tableData]
      const descendingSorted = tableObj.sort((a, b) => b?.aWinningAmount[0] - a?.aWinningAmount[0])
      setTableData(descendingSorted)
    } else {
      const tableObj = [...tableData]
      const descendingSorted = tableObj.sort((a, b) => b[sortType] - a[sortType])
      setTableData(descendingSorted)
    }
  }

  function handleFilter(e, type) {
    setFilterToggle(false)
    if (e.target.checked) {
      switch (type) {
        case 'two':
          setTwoPlayerChecked(!twoPlayerChecked)
          break
        case 'six':
          setSixPlayerChecked(!sixPlayerChecked)
          break
      }
    } else {
      switch (type) {
        case 'two':
          setTwoPlayerChecked(!twoPlayerChecked)
          break
        case 'six':
          setSixPlayerChecked(!sixPlayerChecked)
          break
      }
    }
  }

  const resetFilter = () => {
    setTwoPlayerChecked(false)
    setSixPlayerChecked(false)
  }

  useEffect(() => {
    if ((!twoPlayerChecked && !sixPlayerChecked) || (twoPlayerChecked && sixPlayerChecked)) {
      setTableData(clubGameData)
    } else if (twoPlayerChecked && !sixPlayerChecked) {
      setTableData(
        tableData?.filter((table) => {
          return table?.nMaxPlayer === 2
        })
      )
    } else if (!twoPlayerChecked && sixPlayerChecked) {
      setTableData(
        tableData?.filter((table) => {
          return table?.nMaxPlayer === 6
        })
      )
    }
  }, [twoPlayerChecked || sixPlayerChecked])

  function handleClubGamePopup() {
    dispatch(joinClubTable(tableId, token))
    setShowConfirmPlay(false)
    setTableId('')
  }

  function handlePopup(tableId) {
    if (props.showMmodal) {
      setShowMmodal(true)
    } else {
      setShowConfirmPlay(true)
      setTableId(tableId)
    }
  }

  function handleJoinClub() {
    setLoading(true)
    dispatch(joinClubRequest(clubCode, token))
  }

  function joinClubToggle() {
    if (props.showMmodal) {
      setShowMmodal(true)
    } else {
      setjoinClub(!joinClub)
      if (joinClub === false) {
        setLoading(true)
        dispatch(getClubRequestList(token))
      }
    }
  }

  function dateFormat(date) {
    return moment(date).format('MMMM DD, YYYY, hh:mm A')
  }

  function handleLeaveClub(clubId) {
    setLoading(true)
    dispatch(leaveClub(clubId, token))
  }

  const toggleSubTab = (tab) => {
    if (subTab !== tab) setSubTab(tab)
    setTwoPlayerChecked(false)
    setSixPlayerChecked(false)
  }
  const leaveClubModalToggle = () => setleaveClubModal(!leaveClubModal)
  const clubInfoModalToggle = () => setclubInfoModal(!clubInfoModal)
  const passbookModalToggle = () => setpassbookModal(!passbookModal)
  const withdrawModalToggle = () => {
    setwithdrawModal(!withdrawModal)
    setWithdrawAmount('')
  }
  const transactionModalToggle = () => settransactionModal(!transactionModal)
  const depositModalToggle = () => {
    setdepositModal(!depositModal)
    setDepositAmount('')
  }

  function handleWithdrawAmount(e) {
    const value = e.target.value.replace(/[^\d]/, '')
    if (parseInt(value) !== 0) {
      setWithdrawAmount(value)
    }
    if (verifyLength(e.target.value, 1)) {
      setSubmitWithdrawDisable(true)
    } else if (!verifyLength(e.target.value, 1)) {
      setSubmitWithdrawDisable(false)
    }
    if (e.target.value != 0) {
      setSubmitWithdrawDisable(true)
    } else {
      setSubmitWithdrawDisable(false)
    }
  }

  function handleWithdrawClub() {
    setLoading(true)
    dispatch(clubWithdraw(clubId, withdrawAmount, token))
  }

  function handleCancelClubWithdraw(id) {
    setLoading(true)
    dispatch(cancelClubWithdraw(id, token))
  }

  function handleCancelClubDeposit(id) {
    setLoading(true)
    dispatch(cancelClubDeposit(id, token))
  }

  function switchToTableTransaction(tableId) {
    setSwitchToTable(true)
    if (switchToTable === false) {
      setLoading(true)
      dispatch(getClubTableTransaction(clubId, tableId, token))
    }
  }

  function handleClubDeposit() {
    if (minDepositAmount > 0) {
      setLoading(true)
      dispatch(clubDeposit(clubId, minDepositAmount, token))
    }
  }

  function handleDepositAmount(e) {
    const value = e.target.value.replace(/[^\d]/, '')
    if (parseInt(value) !== 0) {
      setDepositAmount(value)
      setMinDepositAmount(value)
    }
    if (e.target.value != 0) {
      setDisableDeposit(true)
    } else {
      setDisableDeposit(false)
    }
  }

  function handleFixedAmount(amount) {
    setMinDepositAmount(amount)
    setDepositAmount(amount)
    setDisableDeposit(true)
  }

  function handleLeaveClubFromInfo() {
    setLoading(true)
    dispatch(leaveClubFromInfo(clubId, token))
  }

  return (
    <>
      <div>
        {loading && <Loading />}
        <div className={switchToClubDetails ? 'd-none' : 'table-cnt table-cnt-box club-cnt club-page'}>
          <div className="d-flex justify-content-end align-items-center">
            <Button type="button" className="common-btn yellow-btn club-btn" onClick={joinClubToggle}>
              Join new club
            </Button>
            <Modal isOpen={joinClub} toggle={joinClubToggle} className="join-modal club-modal">
              <ModalHeader toggle={joinClubToggle} className="text-center text-capitalize">
                Join new club
              </ModalHeader>
              <ModalBody>
                <div className="mx-auto club-btn-box ">
                  <Form className="d-flex align-items-center">
                    <FormGroup>
                      <Input
                        type="text"
                        maxLength="10"
                        name="text"
                        id="exampletext"
                        onChange={(e) => setClubCode(e.target.value)}
                        placeholder="Enter Club ID"
                        value={clubCode}
                      />
                    </FormGroup>
                    <Button
                      type="button"
                      onClick={handleJoinClub}
                      disabled={clubCode?.length !== 10}
                      className="common-btn yellow-btn flex-shrink-0"
                    >
                      JOIN CLUB
                    </Button>
                  </Form>
                </div>
                <div className="club-listing-wrap">
                  <h5 className="white-txt mb-20">Club Requests</h5>
                  <Table responsive className="common-table">
                    <thead>
                      <tr>
                        <th className="text-center">Club name</th>
                        <th className="text-center">Club ID</th>
                        <th className="text-center">Date Requested</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clubRequestList?.length ? (
                        clubRequestList.map((request, i) => (
                          <tr key={i}>
                            <td className="text-center favourite">
                              <img src={request?.sLogo} alt="club_image" /> {request?.sName}
                            </td>
                            <td className="text-center text-uppercase">{request?.iClubId}</td>
                            <td className="text-center word-wrap">{dateFormat(request?.dJoinedDate)}</td>
                            <td className="text-center">
                              <p className="yellow-txt"> {request?.eState} </p>
                            </td>
                            <td>
                              <div className="text-end text-center arrow-btn-box">
                                <button
                                  type="button"
                                  onClick={() => handleLeaveClub(request?.iClubId)}
                                  className="common-btn red-btn text-capitalize"
                                >
                                  cancel request
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="tournament-cnt d-flex justify-content-center">
                          <div className="table-cnt table-cnt-box text-center">
                            <p className="text-white">You haven&apos;t requested any club!!</p>
                          </div>
                        </div>
                      )}
                    </tbody>
                  </Table>
                </div>
              </ModalBody>
            </Modal>
          </div>
          <div>
            <div>
              <Table responsive className="common-table">
                <thead>
                  <tr>
                    <th className="text-center">Club name</th>
                    <th className="text-center">Club Code</th>
                    <th>
                      <div className="d-flex align-items-center justify-content-center">Club balance</div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center justify-content-center">Details</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {clubList?.length ? (
                    clubList.map((club, i) => (
                      <>
                        <tr key={i}>
                          <td>
                            <div className="favourite d-flex text-center justify-content-center align-items-center">
                              <img src={club?.sLogo} alt="Back-Arrow" /> <span>{club?.sName}</span>
                            </div>
                          </td>
                          <td className="text-center">{club?.sCode}</td>

                          <td className="text-center">{club?.nChips?.toFixed(2)}</td>
                          <td>
                            <div className="text-end text-center arrow-btn-box">
                              <button
                                type="button"
                                onClick={() => handleClubDetailSwitch(club?.sName, club?.iClubId)}
                                className="common-btn arrow-btn"
                              ></button>
                            </div>
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <div className="tournament-cnt d-flex justify-content-center">
                      <div className="table-cnt table-cnt-box text-center">
                        <p>You haven&apos;t joined any club!!</p>
                      </div>
                    </div>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div>
          <div className={switchToClubDetails ? 'table-cnt table-cnt-box club-cnt' : 'd-none'}>
            <div className="club-header-wrap d-flex justify-content-between mb-20 align-items-center">
              <Button
                className="common-btn back-btn small-btn back-btn"
                onClick={() => {
                  setSwitchToClubDetails(false)
                }}
              >
                <img src={BackArrow} alt="Back-Arrow" />
              </Button>
              <h3>{clubName}</h3>
              <div className="filter-section d-flex align-items-center">
                <Button type="button" id="menuIcon">
                  <img src={menu} alt="menu" onClick={clubDetailModalToggle} />
                </Button>
                <Button type="button" id="infoTooltipClub">
                  <img src={info} alt="info" />
                </Button>
                <UncontrolledTooltip autohide={false} placement="bottom" target="infoTooltipClub" className="pop-desc">
                  {gameDetails}
                </UncontrolledTooltip>
                <Button
                  type="button"
                  onClick={() => setFilterToggle((filterToggle) => !filterToggle)}
                  className={'btn btn-secondary filter-btn'}
                >
                  <img src={filter} alt="filter" />
                </Button>
              </div>
              <Modal isOpen={clubDetailModal} toggle={clubDetailModalToggle} className="join-modal menu-modal image-header-modal">
                <ModalHeader toggle={clubDetailModalToggle} className="d-flex align-item-center">
                  <div className="d-flex align-items-center flex-wrap justify-content-start">
                    <img src={clubInfo?.sLogo} alt="club-icon" /> <h4 className="mb-0">{clubName}</h4>
                  </div>
                  <div className="d-flex align-items-center flex-wrap justify-content-start">
                    <p>Club Balance : &nbsp;</p>
                    <p>
                      <b>{clubBalance?.nChips?.toFixed(2)}</b>
                    </p>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <Table responsive className="common-table">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td className="d-flex flex-wrap align-items-center justify-content-between">
                          <div className="d-flex flex-wrap  align-items-center justify-content-start">
                            <img src={deposit} alt="arrow-icon" /> <p> Deposit </p>
                          </div>
                          <td>
                            <div className="text-end text-center arrow-btn-box">
                              <button type="button" className="common-btn arrow-btn" onClick={depositModalToggle}></button>
                            </div>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex flex-wrap align-items-center justify-content-between">
                          <div className="d-flex flex-wrap  align-items-center justify-content-start">
                            <img src={withdraw} alt="arrow-icon" /> <p> Withdrawl </p>
                          </div>
                          <td>
                            <div className="text-end text-center arrow-btn-box" onClick={withdrawModalToggle}>
                              <button type="button" className="common-btn arrow-btn"></button>
                            </div>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex flex-wrap align-items-center justify-content-between">
                          <div className="d-flex flex-wrap  align-items-center justify-content-start">
                            <img src={gameTrans} alt="arrow-icon" /> <p> Game Transactions </p>
                          </div>
                          <td>
                            <div className="text-end text-center arrow-btn-box">
                              <button type="button" className="common-btn arrow-btn" onClick={transactionModalToggle}></button>
                            </div>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex flex-wrap align-items-center justify-content-between">
                          <div className="d-flex flex-wrap  align-items-center justify-content-start">
                            <img src={passbook} alt="arrow-icon" /> <p> Passbook </p>
                          </div>
                          <td>
                            <div className="text-end text-center arrow-btn-box">
                              <button type="button" className="common-btn arrow-btn" onClick={passbookModalToggle}></button>
                            </div>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex flex-wrap align-items-center justify-content-between">
                          <div className="d-flex flex-wrap  align-items-center justify-content-start">
                            <img src={refresh} alt="arrow-icon" /> <p> Club Info </p>
                          </div>
                          <td>
                            <div className="text-end text-center arrow-btn-box">
                              <button type="button" className="common-btn arrow-btn pointer-cursor" onClick={clubInfoModalToggle}></button>
                            </div>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex flex-wrap align-items-center justify-content-between">
                          <div
                            className="d-flex flex-wrap  align-items-center justify-content-start leave-club-btn pointer-cursor"
                            onClick={leaveClubModalToggle}
                          >
                            <img src={exitIcon} alt="icon" /> <p className="red-txt"> Leave Club </p>
                          </div>
                          <td>
                            <div className="text-end text-center arrow-btn-box">
                              <button
                                type="button"
                                className="common-btn arrow-btn pointer-cursor"
                                style={{ opacity: 0, cursor: 'default' }}
                                disabled
                              ></button>
                            </div>
                          </td>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
              {/* leave club modal below */}
              <Modal isOpen={leaveClubModal} toggle={leaveClubModalToggle} className="join-modal leave-club-modal">
                <ModalHeader toggle={leaveClubModalToggle} className="d-flex align-item-center">
                  <div className="d-flex align-items-center flex-wrap justify-content-center">
                    <h3 className="text-center">Leave Club</h3>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <h4 className="notice-line">Are you sure you want to leave the club?</h4>
                  <div className="leave-btn-box d-flex align-items-center flex-wrap justify-content-center">
                    <Button type="button" onClick={() => setleaveClubModal(false)} className="common-btn yellow-btn club-btn">
                      Cancel
                    </Button>
                    <Button type="button" onClick={handleLeaveClubFromInfo} className="common-btn red-btn text-capitalize">
                      leave club
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
              {/* club info modal is below */}
              <Modal isOpen={clubInfoModal} toggle={clubInfoModalToggle} className="join-modal club-info-modal">
                <ModalHeader toggle={clubInfoModalToggle} className="d-flex align-item-center">
                  <div className="d-flex align-items-center flex-wrap justify-content-center">
                    <h3 className="text-center">Club Info</h3>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className="d-flex flex-wrap align-items-center justify-content-between club-info-box">
                    <div className="club-info-left-box">
                      <img src={clubInfo?.sLogo} alt="club-icon" className="mb-20" />
                      <h2 className="text-center text-capitalize">{clubInfo?.sClubName}</h2>
                    </div>
                    <div className="club-info-right-box">
                      <div className="mb-20 d-flex flex-wrap align-items-center">
                        <p>Club Code</p>
                        <p>
                          <b>{clubInfo?.sCode}</b>
                        </p>
                      </div>
                      <div className="mb-20 d-flex flex-wrap align-items-center">
                        <p>Club Owner Name</p>
                        <p>
                          <b>{clubInfo?.sUserName}</b>
                        </p>
                      </div>
                      <div className="mb-20 d-flex flex-wrap align-items-center">
                        <p>Contact</p>
                        <p>
                          <b>{clubInfo?.sMobile}</b>
                        </p>
                      </div>
                      <div className="d-flex flex-wrap align-items-center">
                        <p>Total Members</p>
                        <p>
                          <b>{clubInfo?.nMembers}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              {/* withdraw modal start */}
              <Modal isOpen={withdrawModal} toggle={withdrawModalToggle} className="join-modal withdraw-modal ">
                <ModalHeader toggle={withdrawModalToggle} className="text-center text-capitalize">
                  Withdraw
                </ModalHeader>
                <ModalBody>
                  <div className="mx-auto club-btn-box text-center">
                    <div className="text-center d-flex flex-wrap justify-content-between mb-50">
                      <h4>
                        <b>{clubName} </b>
                      </h4>
                      <h4>
                        Available Withdrawl Balance &nbsp;: <b> &nbsp;₹{clubBalance?.nWithdrawable?.toFixed(2)} </b>
                      </h4>
                    </div>
                    <Form className="">
                      <FormGroup>
                        <Input
                          type="number"
                          onChange={(e) => handleWithdrawAmount(e)}
                          value={withdrawAmount}
                          name="text"
                          id="exampletext1"
                          placeholder="₹ Enter Amount"
                          className="mb-50"
                        />
                      </FormGroup>
                      <Button
                        type="button"
                        onClick={handleWithdrawClub}
                        disabled={!submitWithdrawDisable}
                        className="common-btn yellow-btn flex-shrink-0"
                      >
                        Withdraw
                      </Button>
                    </Form>
                  </div>
                  <div className="club-listing-wrap">
                    <h5 className="white-txt mb-20">Withdrawl Requests</h5>
                    <div className="table-scrollable">
                      <Table responsive className="common-table">
                        <thead>
                          <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Amount</th>
                            <th className="text-center">Date </th>
                            <th className="text-center">Last Update Date</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clubWithdrawList?.length ? (
                            clubWithdrawList.map((withdrawList, i) => (
                              <tr key={i}>
                                <td className="text-center favourite">{withdrawList?._id}</td>
                                <td className="text-center text-uppercase">{withdrawList?.nAmount}</td>
                                <td className="text-center word-wrap">{dateFormat(withdrawList?.dCreatedDate)}</td>
                                <td className="text-center word-wrap">{dateFormat(withdrawList?.dUpdatedDate)}</td>
                                <td className="text-center">{withdrawList?.eStatus}</td>
                                <td>
                                  <div className="text-end text-center arrow-btn-box">
                                    <button
                                      disabled={withdrawList?.eStatus !== 'PENDING'}
                                      type="button"
                                      className="common-btn red-btn text-capitalize"
                                      onClick={() => handleCancelClubWithdraw(withdrawList?._id)}
                                    >
                                      cancel request
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <div className="tournament-cnt d-flex justify-content-center">
                              <div className="table-cnt table-cnt-box text-center">
                                <p className="text-white">You haven&apos; t requested any Withdraw!!</p>
                              </div>
                            </div>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              {/* club passbook modal starts */}
              <Modal isOpen={passbookModal} toggle={passbookModalToggle} className="join-modal club-info-modal passbook-modal">
                <ModalHeader toggle={passbookModalToggle} className="d-flex align-item-center">
                  <div className="d-flex align-items-center flex-wrap justify-content-center">
                    <h3 className="text-center">Passboook</h3>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className="club-listing-wrap">
                    <h5 className="white-txt mb-20">Passbook&apos;s Entries</h5>
                    <div className="table-scrollable">
                      <Table responsive className="common-table">
                        <thead>
                          <tr>
                            <th className="text-center">Reference ID</th>
                            <th className="text-center">Description</th>
                            <th className="text-center">Transaction Amount</th>
                            <th className="text-center">Transaction Type </th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Date & Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clubPassbookTransaction?.length ? (
                            clubPassbookTransaction.map((passT, i) => (
                              <tr key={i}>
                                <td className="text-center favourite">{passT?._id}</td>
                                <td className="text-center text-uppercase">{passT?.sDescription}</td>
                                <td className="text-center text-uppercase">{passT?.nAmount}</td>
                                <td className="text-center word-wrap">{passT?.eType}</td>
                                <td className="text-center">
                                  <p className="yellow-txt text-uppercase"> {passT?.eStatus} </p>
                                </td>
                                <td>
                                  <div className="text-end text-center arrow-btn-box">{dateFormat(passT?.dCreatedDate)}</div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <div className="tournament-cnt d-flex justify-content-center">
                              <div className="table-cnt table-cnt-box text-center">
                                <p className="text-white">Not Available</p>
                              </div>
                            </div>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              {/* Transactions modal starts */}
              <Modal
                isOpen={transactionModal}
                toggle={transactionModalToggle}
                className="join-modal club-info-modal passbook-modal transaction-modal"
              >
                <ModalHeader toggle={transactionModalToggle} className="d-flex align-item-center">
                  <div className="d-flex align-items-center flex-wrap justify-content-center">
                    <h3 className="text-center">Game Transactions</h3>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className={switchToTable ? 'd-none' : 'club-listing-wrap'}>
                    <div className="d-flex text-center justify-content-start align-items-center ">
                      <Button
                        className="common-btn back-btn small-btn back-btn"
                        onClick={() => {
                          transactionModalToggle()
                        }}
                      >
                        <img src={BackArrow} alt="Back-Arrow" />
                      </Button>
                      <h5 className="white-txt mb-20">Game Transaction Entries</h5>
                    </div>
                    <div className="table-scrollable">
                      <Table responsive className="common-table">
                        <thead>
                          <tr>
                            <th className="text-center">Description</th>
                            <th className="text-center">Amount Debited </th>
                            <th className="text-center">Amount Credited </th>
                            <th className="text-center">Bonus Debited</th>
                            <th className="text-center">Bonus Credited</th>
                            <th className="text-center">Date & Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clubGameTransaction?.length ? (
                            clubGameTransaction.map((gameT, i) => (
                              <tr key={i}>
                                <td className="text-center favourite">
                                  <a type="button" onClick={() => switchToTableTransaction(gameT?.iTableId)}>
                                    {gameT?.sDescription}
                                  </a>
                                </td>
                                <td className="text-center text-uppercase">₹{gameT?.nAmountOut?.toFixed(2)}</td>
                                <td className="text-center word-wrap">₹{gameT?.nAmountIn?.toFixed(2)}</td>
                                <td className="text-center">
                                  <p className="yellow-txt text-uppercase"> ₹{gameT?.nBonusOut?.toFixed(2)}</p>
                                </td>
                                <td className="text-center">₹{gameT?.nBonusIn?.toFixed(2)}</td>
                                <td className="text-center">{dateFormat(gameT?.dCreatedDate)}</td>
                              </tr>
                            ))
                          ) : (
                            <div className="tournament-cnt d-flex justify-content-center">
                              <div className="table-cnt table-cnt-box text-center">
                                <p className="text-white">No Game transaction available</p>
                              </div>
                            </div>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className={switchToTable ? 'club-listing-wrap' : 'd-none'}>
                    <div className="d-flex text-center justify-content-start align-items-center  ">
                      <Button
                        className="common-btn back-btn small-btn back-btn"
                        onClick={() => {
                          setSwitchToTable(false)
                        }}
                      >
                        <img src={BackArrow} alt="Back-Arrow" />
                      </Button>
                      <h5 className="white-txt">Table Transaction Entries </h5>
                    </div>

                    <div className="table-scrollable">
                      <Table responsive className="common-table">
                        <thead>
                          <tr>
                            <th className="text-center">Table ID</th>
                            <th className="text-center">Transaction</th>
                            <th className="text-center">Type</th>
                            <th className="text-center">Date & Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clubTableTransaction?.length ? (
                            clubTableTransaction.map((tableT, i) => (
                              <tr key={i}>
                                <td className="text-center">{tableT.iTableId}</td>
                                <td className="text-center">&#8377; {`${tableT.nAmount?.toFixed(2)} (${tableT.sAction})`}</td>
                                <td className="text-center">{tableT.nAmount > 0 ? 'Winning' : 'Loss'}</td>
                                <td className="text-center">
                                  {tableT.dCreatedDate ? dateFormat(tableT.dCreatedDate) : 'No Game transaction available'}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <div className="tournament-cnt d-flex justify-content-center">
                              <div className="table-cnt table-cnt-box text-center">
                                <p className="text-white">No Game transaction available</p>
                              </div>
                            </div>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              {/* deposit modal start */}
              <Modal
                isOpen={depositModal}
                toggle={depositModalToggle}
                className="join-modal Withdraw-modal deposit-modal menu-modal image-header-modal"
              >
                <ModalHeader toggle={depositModalToggle} className="d-flex align-item-center">
                  <div className="d-flex align-items-center flex-wrap justify-content-start">
                    <img src={clubInfo?.sLogo} alt="club-icon" /> <h4 className="mb-0">{clubName}</h4>
                  </div>
                  <div className="d-flex align-items-center flex-wrap justify-content-start">Deposit</div>
                </ModalHeader>
                <ModalBody>
                  <div className="mx-auto club-btn-box text-center">
                    <div className="text-center d-flex flex-wrap justify-content-between mb-50">
                      <h4>
                        <b>{clubName} </b>
                      </h4>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      <h4>
                        Club Wallet Balance &nbsp;: <b> &nbsp;₹{clubBalance?.nChips?.toFixed(2)} </b>
                      </h4>
                    </div>
                    <Form className="">
                      <FormGroup>
                        <Input
                          type="number"
                          name="text"
                          id="exampletext1"
                          placeholder="₹ Enter Amount"
                          className="mb-50"
                          value={depositAmount}
                          onChange={handleDepositAmount}
                        />
                      </FormGroup>
                      <ul className="p-0 list-unstyled suggest-amount d-flex flex-wrap mb-50">
                        <li>
                          <button type="button" onClick={() => handleFixedAmount(100)}>
                            &#8377;100
                          </button>
                        </li>
                        <li>
                          <button type="button" onClick={() => handleFixedAmount(200)}>
                            &#8377;200
                          </button>
                        </li>
                        <li>
                          <button type="button" onClick={() => handleFixedAmount(500)}>
                            &#8377;500
                          </button>
                        </li>
                        <li>
                          <button type="button" onClick={() => handleFixedAmount(1000)}>
                            &#8377;1000
                          </button>
                        </li>
                      </ul>
                      <Button
                        type="button"
                        disabled={!disableDeposit}
                        onClick={handleClubDeposit}
                        className="common-btn yellow-btn flex-shrink-0"
                      >
                        Deposit
                      </Button>
                    </Form>
                  </div>
                  <div className="club-listing-wrap">
                    <h5 className="white-txt mb-20">Deposit Requests</h5>
                    <div className="table-scrollable">
                      <Table responsive className="common-table">
                        <thead>
                          <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Amount</th>
                            <th className="text-center">Date </th>
                            <th className="text-center">Last Update Date</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clubDepositList?.length ? (
                            clubDepositList.map((depositList, i) => (
                              <tr key={i}>
                                <td className="text-center favourite">{depositList?._id}</td>
                                <td className="text-center text-uppercase">{depositList?.nAmount}</td>
                                <td className="text-center word-wrap">{dateFormat(depositList?.dCreatedDate)}</td>
                                <td className="text-center word-wrap">{dateFormat(depositList?.dUpdatedDate)}</td>
                                <td className="text-center">{depositList?.eStatus}</td>
                                <td>
                                  <div className="text-end text-center arrow-btn-box">
                                    <button
                                      disabled={depositList?.eStatus !== 'PENDING'}
                                      type="button"
                                      className="common-btn red-btn text-capitalize"
                                      onClick={() => handleCancelClubDeposit(depositList?._id)}
                                    >
                                      cancel request
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <div className="tournament-cnt d-flex justify-content-center">
                              <div className="table-cnt table-cnt-box text-center">
                                <p className="text-white">You haven&apos; t requested any Deposit!!</p>
                              </div>
                            </div>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: subTab === '8' })}
                    onClick={() => {
                      toggleSubTab('8')
                    }}
                  >
                    Points Rummy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: subTab === '9' })}
                    onClick={() => {
                      toggleSubTab('9')
                    }}
                  >
                    Pools Rummy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: subTab === '10' })}
                    onClick={() => {
                      toggleSubTab('10')
                    }}
                  >
                    Deals Rummy
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className={filterToggle ? 'filter-detail show' : 'filter-detail hide'}>
              <div className="d-flex align-items-center">
                <ul className="filter-list p-0 d-flex align-items-center">
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle caret>Max Players</DropdownToggle>
                      <DropdownMenu>
                        <div>
                          <label htmlFor="chkbox700" className="d-flex align-items-center">
                            <div className="custom-checkbox">
                              <input
                                readOnly
                                id="chkbox700"
                                checked={twoPlayerChecked}
                                type="checkbox"
                                value="2"
                                onClick={(e) => handleFilter(e, 'two')}
                              />
                              <span></span>
                            </div>
                            2 Players
                          </label>
                        </div>
                        <div>
                          <label htmlFor="chkbox800" className="d-flex align-items-center">
                            <div className="custom-checkbox">
                              <input
                                readOnly
                                id="chkbox800"
                                checked={sixPlayerChecked}
                                type="checkbox"
                                value="6"
                                onClick={(e) => handleFilter(e, 'six')}
                              />
                              <span></span>
                            </div>
                            6 Players
                          </label>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                  <li>
                    <button type="button" className="ref-btn border-0 bg-transparent" onClick={resetFilter}>
                      <img src={refresh} alt="refresh" />
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <TabContent activeTab={subTab}>
              <TabPane tabId="8">
                <Table responsive className="common-table">
                  <thead>
                    <tr>
                      <th className="text-center">name</th>
                      <th className="text-center">decks</th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center">
                          point value
                          <div className="order-filter">
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nPoint')}>
                              <img src={upArrow} alt="up-arrow" />
                            </button>
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nPoint')}>
                              <img src={downArrow} alt="down-arrow" />
                            </button>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center">
                          min entry
                          <div className="order-filter">
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMinEntry')}>
                              <img src={upArrow} alt="up-arrow" />
                            </button>
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMinEntry')}>
                              <img src={downArrow} alt="down-arrow" />
                            </button>
                          </div>
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.length ? (
                      tableData.map((pointClub, i) => (
                        <tr key={i}>
                          <td>
                            <div className="favourite d-flex ">
                              <span>{pointClub?.sName}</span>
                            </div>
                          </td>
                          <td className="text-center">2</td>
                          <td className="text-center">{pointClub?.nPoint?.toFixed(2)}</td>
                          <td className="text-center">
                            {pointClub?.nMinEntry} &nbsp;
                            {pointClub?.oBonus?.nValue > 0 && (
                              <>
                                <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0">
                                  <img src={infoIcon} alt="Info" className="table-info-ico" />
                                </Button>
                                <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                                  Bonus utilisation for this table is: {pointClub?.oBonus?.nValue}%
                                </UncontrolledTooltip>
                              </>
                            )}
                          </td>
                          <td>
                            <div className="text-end">
                              <button type="button" className="common-btn" onClick={() => handlePopup(pointClub?._id)}>
                                Play Now
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td colSpan="6" className="text-center">
                            There are no Tables to join.
                            <br /> Please come back later.
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </TabPane>
              <TabPane tabId="9">
                <Table responsive className="common-table">
                  <thead>
                    <tr>
                      <th className="text-center">name</th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center">
                          max players
                          <div className="order-filter">
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMaxPlayer')}>
                              <img src={upArrow} alt="up-arrow" />
                            </button>
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMaxPlayer')}>
                              <img src={downArrow} alt="down-arrow" />
                            </button>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center">
                          Entry Fee
                          <div className="order-filter">
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nEntryFee')}>
                              <img src={upArrow} alt="up-arrow" />
                            </button>
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nEntryFee')}>
                              <img src={downArrow} alt="down-arrow" />
                            </button>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center">
                          Prize
                          <div className="order-filter">
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('aWinningAmount')}>
                              <img src={upArrow} alt="up-arrow" />
                            </button>
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('aWinningAmount')}>
                              <img src={downArrow} alt="down-arrow" />
                            </button>
                          </div>
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.length ? (
                      tableData.map((poolClub, i) => (
                        <tr key={i}>
                          <td>
                            <div className="favourite d-flex ">
                              <span>{poolClub?.sName}</span>
                            </div>
                          </td>
                          <td className="text-center">
                            {poolClub?.nMaxPlayer == 2 ? (
                              <div className="gametable-icon-2">&nbsp;</div>
                            ) : (
                              <div className="gametable-icon-6">&nbsp;</div>
                            )}
                          </td>
                          <td className="text-center">{poolClub?.nEntryFee?.toFixed(2)}</td>
                          <td className="text-center">
                            {poolClub?.aWinningAmount && poolClub?.aWinningAmount[0]} &nbsp;
                            {poolClub?.oBonus?.nValue > 0 && (
                              <>
                                <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0">
                                  <img src={infoIcon} alt="Info" className="table-info-ico" />
                                </Button>
                                <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                                  Bonus utilisation for this table is: {poolClub?.oBonus?.nValue}%
                                </UncontrolledTooltip>
                              </>
                            )}
                          </td>
                          <td>
                            <div className="text-end">
                              <button type="button" onClick={() => handlePopup(poolClub?._id)} className="common-btn">
                                Play Now
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td colSpan="6" className="text-center">
                            There are no Tables to join.
                            <br /> Please come back later.
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </TabPane>
              <TabPane tabId="10">
                <Table responsive className="common-table">
                  <thead>
                    <tr>
                      <th className="text-center">name</th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center">
                          max players
                          <div className="order-filter">
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMaxPlayer')}>
                              <img src={upArrow} alt="up-arrow" />
                            </button>
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMaxPlayer')}>
                              <img src={downArrow} alt="down-arrow" />
                            </button>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center">
                          Entry Fee
                          <div className="order-filter">
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nEntryFee')}>
                              <img src={upArrow} alt="up-arrow" />
                            </button>
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nEntryFee')}>
                              <img src={downArrow} alt="down-arrow" />
                            </button>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center">
                          Prize
                          <div className="order-filter">
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('aWinningAmount')}>
                              <img src={upArrow} alt="up-arrow" />
                            </button>
                            <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('aWinningAmount')}>
                              <img src={downArrow} alt="down-arrow" />
                            </button>
                          </div>
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.length ? (
                      tableData.map((dealClub, i) => (
                        <tr key={i}>
                          <td>
                            <div className="favourite d-flex ">
                              <span>{dealClub?.sName}</span>
                            </div>
                          </td>
                          <td className="text-center">
                            {dealClub?.nMaxPlayer == 2 ? (
                              <div className="gametable-icon-2">&nbsp;</div>
                            ) : (
                              <div className="gametable-icon-6">&nbsp;</div>
                            )}
                          </td>
                          <td className="text-center">{dealClub?.nEntryFee}</td>
                          <td className="text-center">
                            {dealClub?.aWinningAmount && dealClub?.aWinningAmount[0]} &nbsp;
                            {dealClub?.oBonus?.nValue > 0 && (
                              <>
                                <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0">
                                  <img src={infoIcon} alt="Info" className="table-info-ico" />
                                </Button>
                                <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                                  Bonus utilisation for this table is: {dealClub?.oBonus?.nValue}%
                                </UncontrolledTooltip>
                              </>
                            )}
                          </td>
                          <td>
                            <div className="text-end">
                              <button type="button" onClick={() => handlePopup(dealClub?._id)} className="common-btn">
                                Play Now
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td colSpan="6" className="text-center">
                            There are no Tables to join.
                            <br /> Please come back later.
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </TabPane>
            </TabContent>
          </div>
        </div>
        <div className={showConfirmPlay ? 'edit-auth-dtl active' : 'edit-auth-dtl'}>
          <div>
            <p>
              <strong>Are you sure you want to play this game?</strong>
            </p>
            <div className="d-flex side-btn justify-content-center">
              <input type="button" className="common-btn yellow-btn small-btn" value="Play" onClick={handleClubGamePopup} />
              <input type="button" className="common-btn yellow-btn small-btn" value="Cancel" onClick={() => setShowConfirmPlay(false)} />
            </div>
          </div>
        </div>
        <Modal isOpen={showMmodal} toggle={toggleMaintainanceModal} className="join-modal">
          <ModalHeader toggle={toggleMaintainanceModal} className="text-center">
            Can&apos;t play
          </ModalHeader>
          <ModalBody>
            <div className="m-3 ">
              <div className="white-txt text-center">
                <span className="white-txt text-center">Game is Under Maintenance, You can&apos;t play game right now</span>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}

export default connect()(Club)
