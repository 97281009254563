/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import classnames from 'classnames'
import { connect, useDispatch, useSelector } from 'react-redux'
import info from '../../../assets/images/info-icon.svg'
import filter from '../../../assets/images/filter-icon.svg'
import refresh from '../../../assets/images/refresh-icon.svg'
import upArrow from '../../../assets/images/up-arrow.svg'
import downArrow from '../../../assets/images/down-arrow.svg'
import star from '../../../assets/images/star.svg'
import infoIcon from '../../../assets/images/table-info-icon.svg'
import { getLobbyTableList, joinTable } from '../../../actions/lobby'
import Loading from '../../../components/Loading'

function Practice(props) {
  const [subTab, setSubTab] = useState('5')
  const [tableData, setTableData] = useState([])
  const [filterToggle, setFilterToggle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [maxPlayerFilter, setMaxPlayerFilter] = useState(true)
  const [gameDetails, setGameDetails] = useState('')
  const [twoPlayerChecked, setTwoPlayerChecked] = useState(false)
  const [sixPlayerChecked, setSixPlayerChecked] = useState(false)
  const [showMmodal, setShowMmodal] = useState(props.showMmodal)
  const toggleMaintainanceModal = () => setShowMmodal(!showMmodal)

  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const lobbyTableData = useSelector((state) => state.lobby.data)
  const gameSettingsData = useSelector((state) => state.account.data?.oMaintenance)
  const socketModeData = useSelector((state) => state.lobby.mModeData)
  const mainTab = props?.mainTab

  function handleGamePopup(tableId) {
    if (props.showMmodal) {
      setShowMmodal(true)
    } else {
      dispatch(joinTable(tableId, token))
    }
  }

  useEffect(() => {
    if (lobbyTableData) {
      setLoading(false)
      let oldFavIdsArray = []

      if (
        localStorage.getItem('favouritePointPractice') !== null ||
        localStorage.getItem('favouritePoolPractice') !== null ||
        localStorage.getItem('favouriteDealPractice') !== null
      ) {
        if (mainTab === '1' && subTab === '5') {
          const data = JSON.parse(localStorage.getItem('favouritePointPractice') || '[]')
          oldFavIdsArray = handleOldFavourite(data)
        }
        if (mainTab === '1' && subTab === '6') {
          const data = JSON.parse(localStorage.getItem('favouritePoolPractice') || '[]')
          oldFavIdsArray = handleOldFavourite(data)
        }
        if (mainTab === '1' && subTab === '7') {
          const data = JSON.parse(localStorage.getItem('favouriteDealPractice') || '[]')
          oldFavIdsArray = handleOldFavourite(data)
        }
        setTableData(oldFavIdsArray)
      } else {
        setTableData(lobbyTableData)
      }
    }
  }, [lobbyTableData])

  function handleOldFavourite(data) {
    const oldFavData = []
    lobbyTableData.forEach((table) => {
      if (data.includes(table._id.toString())) {
        oldFavData.push({ ...table, favourite: true })
        return
      }
      oldFavData.push({ ...table, favourite: false })
    })

    oldFavData.forEach(function (item, i) {
      if (item?.favourite) {
        oldFavData.splice(i, 1)
        oldFavData.unshift(item)
      }
    })
    return oldFavData
  }

  const toggleSubTab = (tab) => {
    if (subTab !== tab) setSubTab(tab)
    setTwoPlayerChecked(false)
    setSixPlayerChecked(false)
  }

  useEffect(() => {
    if (mainTab === '1' && subTab === '5') {
      setLoading(true)
      dispatch(getLobbyTableList('point', 'practice', token))
      setFilterToggle(false)
      setGameDetails('Play for points which have a pre-decided value. One winner wins the complete prize pool at the end of each game.')
    }
    if (mainTab === '1' && subTab === '6') {
      setLoading(true)
      dispatch(getLobbyTableList('pool', 'practice', token))
      setFilterToggle(false)
      setGameDetails(
        'Play until score reaches the maximum limit [101 or 201]. Winner of a deal gets 0 points and the rest accumulate points which are added to their score. A fixed entry fee forms the prize pool.'
      )
    }
    if (mainTab === '1' && subTab === '7') {
      setLoading(true)
      dispatch(getLobbyTableList('deal', 'practice', token))
      setFilterToggle(false)
      setGameDetails(
        'Play with chips that are allocated at the beginning of the deal for pre-decided number of deals. One winner wins all the chips at the end of each deal. At the end of pre-decided number of deals, player with  maximum number of chips is selected as the winner for the game.'
      )
    }
  }, [mainTab, subTab])

  function handleStarPracPoint(id, type) {
    const matchedTables = tableData.map((t) => {
      if (t._id === id) {
        if (t?.favourite) {
          return { ...t, favourite: false }
        } else {
          return { ...t, favourite: true }
        }
      }
      return { ...t }
    })
    matchedTables.forEach(function (item, i) {
      if (item?.favourite) {
        matchedTables.splice(i, 1)
        matchedTables.unshift(item)
      }
    })

    const favouriteIds = matchedTables.filter((res) => res.favourite).map((ele) => ele._id)

    if (type == 'pointPractice') {
      localStorage.setItem('favouritePointPractice', JSON.stringify(favouriteIds))
    }
    if (type == 'poolPractice') {
      localStorage.setItem('favouritePoolPractice', JSON.stringify(favouriteIds))
    }
    if (type == 'dealPractice') {
      localStorage.setItem('favouriteDealPractice', JSON.stringify(favouriteIds))
    }
    setTableData(matchedTables)
  }

  function handleAscending(sortType) {
    const tableObj = [...tableData]
    const ascendingSorted = tableObj.sort((a, b) => a[sortType] - b[sortType])
    setTableData(ascendingSorted)
  }
  function handleDescending(sortType) {
    const tableObj = [...tableData]
    const descendingSorted = tableObj.sort((a, b) => b[sortType] - a[sortType])
    setTableData(descendingSorted)
  }

  function handleFilter(e, type) {
    setFilterToggle(false)
    setMaxPlayerFilter((maxPlayerFilter) => !maxPlayerFilter)
    switch (type) {
      case 'two':
        setTwoPlayerChecked(!twoPlayerChecked)
        break
      case 'six':
        setSixPlayerChecked(!sixPlayerChecked)
        break
    }
    if (maxPlayerFilter) {
      const tableObj = [...tableData]
      const result = tableObj.filter((tb) => (e.target.checked ? tb.nMaxPlayer == e.target.value : tb.nMaxPlayer != e.target.value))
      setTableData(result)
    } else {
      if (mainTab === '1' && subTab === '5') {
        if (localStorage.getItem('favouritePointPractice') === null) {
          dispatch(getLobbyTableList('point', 'practice', token))
        } else {
          const data = JSON.parse(localStorage.getItem('favouritePointPractice') || '[]')
          const oldFilter = handleOldFilter(data)
          setTableData(oldFilter)
        }
      }
      if (mainTab === '1' && subTab === '6') {
        if (localStorage.getItem('favouritePoolPractice') === null) {
          dispatch(getLobbyTableList('pool', 'practice', token))
        } else {
          const data = JSON.parse(localStorage.getItem('favouritePoolPractice') || '[]')
          const oldFilter = handleOldFilter(data)
          setTableData(oldFilter)
        }
      }
      if (mainTab === '1' && subTab === '7') {
        if (localStorage.getItem('favouriteDealPractice') === null) {
          dispatch(getLobbyTableList('deal', 'practice', token))
        } else {
          const data = JSON.parse(localStorage.getItem('favouriteDealPractice') || '[]')
          const oldFilter = handleOldFilter(data)
          setTableData(oldFilter)
        }
      }
    }
  }

  function handleOldFilter(data) {
    const newData = []
    lobbyTableData.forEach((td) => {
      if (data.includes(td._id.toString())) {
        newData.push({ ...td, favourite: true })
        return
      }
      newData.push({ ...td, favourite: false })
    })
    return newData
  }

  function resetFilter() {
    setFilterToggle(false)
    setMaxPlayerFilter(true)
    setTwoPlayerChecked(false)
    setSixPlayerChecked(false)
    if (mainTab === '1' && subTab === '5') {
      if (localStorage.getItem('favouritePointPractice') === null) {
        dispatch(getLobbyTableList('point', 'practice', token))
      } else {
        const data = JSON.parse(localStorage.getItem('favouritePointPractice') || '[]')
        const resetData = handleOldFilter(data)
        setTableData(resetData)
      }
    }
    if (mainTab === '1' && subTab === '6') {
      if (localStorage.getItem('favouritePoolPractice') === null) {
        dispatch(getLobbyTableList('pool', 'practice', token))
      } else {
        const data = JSON.parse(localStorage.getItem('favouritePoolPractice') || '[]')
        const resetData = handleOldFilter(data)
        setTableData(resetData)
      }
    }
    if (mainTab === '1' && subTab === '7') {
      if (localStorage.getItem('favouriteDealPractice') === null) {
        dispatch(getLobbyTableList('deal', 'practice', token))
      } else {
        const data = JSON.parse(localStorage.getItem('favouriteDealPractice') || '[]')
        const resetData = handleOldFilter(data)
        setTableData(resetData)
      }
    }
  }

  return (
    <div>
      {loading && <Loading />}
      <div className="table-cnt table-cnt-box">
        <div className="d-flex justify-content-between align-items-center">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: subTab === '5' })}
                onClick={() => {
                  toggleSubTab('5')
                }}
              >
                Points Rummy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: subTab === '6' })}
                onClick={() => {
                  toggleSubTab('6')
                }}
              >
                Pools Rummy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: subTab === '7' })}
                onClick={() => {
                  toggleSubTab('7')
                }}
              >
                Deals Rummy
              </NavLink>
            </NavItem>
          </Nav>
          <div className="filter-section d-flex align-items-center">
            <Button type="button" id="infoTooltip">
              <img src={info} alt="info" />
            </Button>
            <UncontrolledTooltip autohide={false} placement="bottom" target="infoTooltip" className="pop-desc">
              {gameDetails}
            </UncontrolledTooltip>
            <Button
              type="button"
              onClick={() => setFilterToggle((filterToggle) => !filterToggle)}
              className={'btn btn-secondary filter-btn'}
            >
              <img src={filter} alt="filter" />
            </Button>
          </div>
        </div>
        <div className={filterToggle ? 'filter-detail show' : 'filter-detail hide'}>
          <div className="d-flex align-items-center">
            <ul className="filter-list p-0 d-flex align-items-center">
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle caret>Max Players</DropdownToggle>
                  <DropdownMenu>
                    <div>
                      <label htmlFor="chkbox70" className="d-flex align-items-center">
                        <div className="custom-checkbox">
                          <input
                            readOnly
                            id="chkbox70"
                            checked={twoPlayerChecked}
                            type="checkbox"
                            value="2"
                            onClick={(e) => handleFilter(e, 'two')}
                          />
                          <span></span>
                        </div>
                        2 Players
                      </label>
                    </div>
                    <div>
                      <label htmlFor="chkbox80" className="d-flex align-items-center">
                        <div className="custom-checkbox">
                          <input
                            readOnly
                            id="chkbox80"
                            checked={sixPlayerChecked}
                            type="checkbox"
                            value="6"
                            onClick={(e) => handleFilter(e, 'six')}
                          />
                          <span></span>
                        </div>
                        6 Players
                      </label>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
              <li>
                <button type="button" className="ref-btn border-0 bg-transparent" onClick={resetFilter}>
                  <img src={refresh} alt="refresh" /> Reset
                </button>
              </li>
            </ul>
          </div>
        </div>
        <TabContent activeTab={subTab}>
          <TabPane tabId="5">
            <Table responsive className="common-table">
              <thead>
                <tr>
                  <th className="text-center">name</th>
                  <th className="text-center">decks</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      max players
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMaxPlayer')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMaxPlayer')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      point value
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nPoint')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nPoint')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      min entry
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMinEntry')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMinEntry')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length ? (
                  tableData.map((pointPrac, i) => (
                    <tr key={i}>
                      <td>
                        <div className="favourite d-flex ">
                          <button
                            type="button"
                            onClick={() => handleStarPracPoint(pointPrac._id, 'pointPractice')}
                            className={pointPrac?.favourite ? 'active border-0 bg-transparent' : 'border-0 bg-transparent'}
                          >
                            <img src={star} alt="star" />
                          </button>
                          <span>{pointPrac?.sName}</span>
                        </div>
                      </td>
                      <td className="text-center">2</td>
                      <td className="text-center">
                        {pointPrac?.nMaxPlayer == 2 ? (
                          <div className="gametable-icon-2">&nbsp;</div>
                        ) : (
                          <div className="gametable-icon-6">&nbsp;</div>
                        )}
                      </td>
                      <td className="text-center">{pointPrac?.nPoint?.toFixed(2)}</td>
                      <td className="text-center">
                        {pointPrac?.nMinEntry} &nbsp;
                        {pointPrac?.oBonus?.nValue > 0 && (
                          <>
                            <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0">
                              <img src={infoIcon} alt="Info" className="table-info-ico" />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                              Bonus utilisation for this table is: {pointPrac?.oBonus?.nValue}%
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                      <td>
                        <div className="text-end">
                          <button type="button" onClick={() => handleGamePopup(pointPrac?._id)} className="common-btn">
                            Play Now
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        There are no Tables to join.
                        <br /> Please come back later.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </TabPane>
          <TabPane tabId="6">
            <Table responsive className="common-table">
              <thead>
                <tr>
                  <th className="text-center">name</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Type
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nPool')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nPool')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      max players
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMaxPlayer')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMaxPlayer')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Entry Fee
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nEntryFee')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nEntryFee')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Prize
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('aWinningAmount[0]')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('aWinningAmount[0]')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length ? (
                  tableData.map((poolPrac, i) => (
                    <tr key={i}>
                      <td>
                        <div className="favourite d-flex ">
                          <button
                            onClick={() => handleStarPracPoint(poolPrac._id, 'poolPractice')}
                            type="button"
                            className={poolPrac?.favourite ? 'active border-0 bg-transparent' : 'border-0 bg-transparent'}
                          >
                            <img src={star} alt="star" />
                          </button>
                          <span>{poolPrac?.sName}</span>
                        </div>
                      </td>
                      <td className="text-center">{poolPrac?.nPool}</td>
                      <td className="text-center">
                        {poolPrac?.nMaxPlayer == 2 ? (
                          <div className="gametable-icon-2">&nbsp;</div>
                        ) : (
                          <div className="gametable-icon-6">&nbsp;</div>
                        )}
                      </td>
                      <td className="text-center">{poolPrac?.nEntryFee?.toFixed(2)}</td>
                      <td className="text-center">
                        {poolPrac?.aWinningAmount && poolPrac?.aWinningAmount[0]} &nbsp;
                        {poolPrac?.oBonus?.nValue > 0 && (
                          <>
                            <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0">
                              <img src={infoIcon} alt="Info" className="table-info-ico" />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                              Bonus utilisation for this table is: {poolPrac?.oBonus?.nValue}%
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                      <td>
                        <div className="text-end">
                          <button type="button" onClick={() => handleGamePopup(poolPrac?._id)} className="common-btn">
                            Play Now
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        There are no Tables to join.
                        <br /> Please come back later.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </TabPane>
          <TabPane tabId="7">
            <Table responsive className="common-table">
              <thead>
                <tr>
                  <th className="text-center">name</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Deals
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nDeal')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nDeal')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      max players
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nMaxPlayer')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nMaxPlayer')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Entry Fee
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('nEntryFee')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('nEntryFee')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-center">
                      Prize
                      <div className="order-filter">
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending('aWinningAmount[0]')}>
                          <img src={upArrow} alt="up-arrow" />
                        </button>
                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending('aWinningAmount[0]')}>
                          <img src={downArrow} alt="down-arrow" />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length ? (
                  tableData.map((dealPrac, i) => (
                    <tr key={i}>
                      <td>
                        <div className="favourite d-flex ">
                          <button
                            type="button"
                            onClick={() => handleStarPracPoint(dealPrac._id, 'dealPractice')}
                            className={dealPrac?.favourite ? 'active border-0 bg-transparent' : 'border-0 bg-transparent'}
                          >
                            <img src={star} alt="star" />
                          </button>
                          <span>{dealPrac?.sName}</span>
                        </div>
                      </td>
                      <td className="text-center">{dealPrac?.nDeal}</td>
                      <td className="text-center">
                        {dealPrac?.nMaxPlayer == 2 ? (
                          <div className="gametable-icon-2">&nbsp;</div>
                        ) : (
                          <div className="gametable-icon-6">&nbsp;</div>
                        )}
                      </td>
                      <td className="text-center">{dealPrac?.nEntryFee}</td>
                      <td className="text-center">
                        {dealPrac?.aWinningAmount && dealPrac?.aWinningAmount[0]} &nbsp;
                        {dealPrac?.oBonus?.nValue > 0 && (
                          <>
                            <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0">
                              <img src={infoIcon} alt="Info" className="table-info-ico" />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                              Bonus utilisation for this table is: {dealPrac?.oBonus?.nValue}%
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                      <td>
                        <div className="text-end">
                          <button type="button" onClick={() => handleGamePopup(dealPrac?._id)} className="common-btn">
                            Play Now
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        There are no Tables to join.
                        <br /> Please come back later.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </TabPane>
        </TabContent>
      </div>
      <Modal isOpen={showMmodal} toggle={toggleMaintainanceModal} className="join-modal">
        <ModalHeader toggle={toggleMaintainanceModal} className="text-center">
          Can&apos;t play
        </ModalHeader>
        <ModalBody>
          <div className="m-3 ">
            <div className="white-txt text-center">
              <span className="white-txt text-center">Game is Under Maintenance, You can&apos;t play game right now</span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default connect()(Practice)
