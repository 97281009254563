const stag = {
  API_URL: 'https://stag.rummy24.com/api/v1',
  API_URL_V2: 'https://stag.rummy24.com/api/v2',
  SOCKET_URL: 'https://stag.rummy24.com/',
  GAME_URL: 'http://rummy24-game-phaser.s3-website.ap-south-1.amazonaws.com',
  SITE_KET:'6LcfQJYnAAAAAJtTORYUDhrZMt5nDq2eM2RKSyoE'
}

const prod = {
  API_URL: 'https://backend.rummy24.com/api/v1',
  API_URL_V2: 'https://backend.rummy24.com/api/v2',
  SOCKET_URL: 'https://backend.rummy24.com/',
  GAME_URL: 'https://game.rummy24.com',
  SITE_KET:'6Le9F5EnAAAAAAFNJR1r0T5xMCwSFHS8O19eiB40'
}

export default stag
