import axios from '../axios'

import constants from '../constants'

const errMsg = 'Server is unavailable.'

export const getClubList = (token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.get("/club/list", {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_CLUBLIST,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_CLUBLIST,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getClubGames = (iClubId, eRummyType, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post("/club/game/list", {
        iClubId, eRummyType
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_CLUB_GAMES,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_CLUB_GAMES,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const joinClubTable = (iProtoId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post("/club/join/table", {
        iProtoId
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.JOIN_CLUB_TABLE,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.JOIN_CLUB_TABLE,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const joinClubRequest = (sCode, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post("/club/join", {
        sCode
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.JOIN_CLUB,
                payload: {
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.JOIN_CLUB,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getClubRequestList = (token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.get("/club/pendingClubs", {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_CLUB_REQUEST_LIST,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_CLUB_REQUEST_LIST,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const leaveClub = (iClubId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post("/club/cancel|leave", {
        iClubId, sAction: "left"
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.LEAVE_CLUB,
                payload: {
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.LEAVE_CLUB,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getClubInfo = (iClubId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post("/club", {
        iClubId
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.CLUB_INFO,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.CLUB_INFO,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getClubBalance = (iClubId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post("/club/balance", {
        iClubId
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_CLUB_BALANCE,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_CLUB_BALANCE,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const clubWithdraw = (iClubId, nAmount, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post("/club/withdraw", {
        iClubId, nAmount: parseInt(nAmount)
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.CLUB_WITHDRAW,
                payload: {
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.CLUB_WITHDRAW,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getClubWithdrawList = (iClubId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post(`/club/list/withdrawal?iClubId=${iClubId}`, {}, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_CLUB_WITHDRAW_LIST,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_CLUB_WITHDRAW_LIST,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}


export const cancelClubWithdraw = (iTransactionId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post('/club/withdraw/cancel', {
        iTransactionId
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.CANCEL_CLUB_WITHDRAW,
                payload: {
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.CANCEL_CLUB_WITHDRAW,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getClubGameTransaction = (iClubId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post('/club/gameTransactions', {
        iClubId
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.CLUB_GAME_TRANSACTION,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.CLUB_GAME_TRANSACTION,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getClubTableTransaction = (iClubId, iTableId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post('/club/gameTransactions', {
        iClubId, iTableId
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.CLUB_TABLE_TRANSACTION,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.CLUB_TABLE_TRANSACTION,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}


export const getClubPassbook = (iClubId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post('/club/passbook', {
        iClubId
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.CLUB_PASSBOOK,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.CLUB_PASSBOOK,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const clubDeposit = (iClubId, nAmount, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post("/club/deposit", {
        iClubId, nAmount: parseInt(nAmount)
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.CLUB_DEPOSIT,
                payload: {
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.CLUB_DEPOSIT,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getClubDepositList = (iClubId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post(`/club/list/deposit?iClubId=${iClubId}`, {}, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_CLUB_DEPOSIT_LIST,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_CLUB_DEPOSIT_LIST,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const cancelClubDeposit = (iTransactionId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post('/club/deposit/cancel', {
        iTransactionId
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.CANCEL_CLUB_DEPOSIT,
                payload: {
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.CANCEL_CLUB_DEPOSIT,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const leaveClubFromInfo = (iClubId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_CLUB })
    axios.post("/club/cancel|leave", {
        iClubId, sAction: "left"
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.LEAVE_CLUB_FROM_INFO,
                payload: {
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.LEAVE_CLUB_FROM_INFO,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}
