import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Collapse, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getSettings } from '../../actions/account'
import { toast } from 'react-toastify'
import { depositCash, getPromocodeList } from '../../actions/deposit'
import Loading from '../../components/Loading'
import { history } from '../../App'
import Countdown from 'react-countdown'
import Timer from '../../components/Timer'

function DepositCash() {
  const [loading, setLoading] = useState(false)
  const [restrictedStates, setRestrictedStates] = useState([])
  const [minAmount, setMinAmount] = useState(0)
  const [maxAmount, setMaxAmount] = useState(0)
  const [promocodeList, setPromocodeList] = useState([])
  const [promoID, setPromoID] = useState('')
  const [swithToRemove, setSwithToRemove] = useState(false)
  const [promoArray, setPromoArray] = useState({})
  const [amount, setAmount] = useState('')
  const [disableDeposit, setDisableDeposit] = useState(false)
  const [isOpenPromo, setIsOpenPromo] = useState(false)
  const [modal, setModal] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [promoTimer, setPromoTimer] = useState(true)
  const modalRef = useRef()
  const dispatch = useDispatch()
  const gameSettings = useSelector((state) => state.account.data)
  const token = useSelector((state) => state.auth.token)
  const promoList = useSelector((state) => state.deposit.listData)
  const depositData = useSelector((state) => state.deposit.depositData)

  const depositStatus = useSelector((state) => state.deposit.resStatusDeposit)
  const depositMessage = useSelector((state) => state.deposit.resMessageDeposit)
  const resStatusDeposit = useSelector((state) => state.deposit.resStatusDeposit)
  const previousProps = useRef({ depositMessage, depositStatus, depositData }).current

  const toggle = () => {
    setIsOpenPromo(!isOpenPromo)
  }

  useEffect(() => {
    if (isOpenPromo && amount == 0) {
      setPromocodeList([])
    } else if (isOpenPromo && amount >= minAmount) {
      dispatch(getPromocodeList(amount, token))
    }
  }, [isOpenPromo, amount])

  const toggleModal = () => setModal(!modal)

  useEffect(() => {
    dispatch(getSettings())
    setAmount('')
    document.title = 'Rummy 24 | Deposit'
  }, [])

  useEffect(() => {
    if (previousProps.depositMessage !== depositMessage) {
      if (depositMessage?.length && depositMessage !== 'success') {
        toast(
          <div>
            <div>{depositMessage}</div>
          </div>
        )
      }
    }
    return () => {
      previousProps.depositMessage = depositMessage
    }
  }, [depositMessage])

  useEffect(() => {
    if (gameSettings) {
      setRestrictedStates(gameSettings?.aRestrictedState)
      setMinAmount(gameSettings?.oPurchceRange?.nMinimum)
      setMaxAmount(gameSettings?.oPurchceRange?.nMaximum)
    }
  }, [gameSettings])

  onbeforeunload = (event) => {
    if (modal) {
      event.returnValue = ''
    }
  }

  const dropinConfig = {
    components: ['order-details', 'card', 'netbanking', 'app', 'upi'],
    onSuccess: function () {
      setModal(false)
      toast(
        <div>
          <div>{'Payment Successful'}</div>
        </div>
      )
      history.push('/my-account')
    },
    onFailure: function (data) {
      toast(
        <div>
          <div>{data?.order?.errorText || data?.transaction?.txMsg || 'Payment Faild'}</div>
        </div>
      )
    },
    style: {
      backgroundColor: '#ffffff',
      color: '#3752de',
      fontFamily: 'Helvetica',
      fontSize: '14px',
      errorColor: '#ff0000',
      theme: 'light',
      maxWidth: '100%',
    },
  }

  useEffect(() => {
    if (!resStatusDeposit) {
      setModal(false)
      setAmount('')
    }
  }, [resStatusDeposit])

  useEffect(() => {
    if (previousProps.depositData !== depositData) {
      if (depositData?.payment_session_id) {
        setLoading(false)
        setOrderId(depositData?.orderId)
        // eslint-disable-next-line no-undef
        const cf = new Cashfree(depositData?.payment_session_id)
        cf.drop(modalRef.current, dropinConfig)
      }
    }
    return () => {
      previousProps.depositData = depositData
    }
  }, [depositData])

  useEffect(() => {
    if (previousProps.depositStatus !== depositStatus) {
      if (depositStatus) {
        setLoading(false)
      }
      if (!depositStatus) {
        setLoading(false)
        setDisableDeposit(false)
        setSwithToRemove(false)
      }
    }
    return () => {
      previousProps.depositStatus = depositStatus
    }
  }, [depositStatus])

  useEffect(() => {
    if (promoList) {
      setPromocodeList(promoList)
      setPromoTimer(true)
    }
  }, [promoList])

  useEffect(() => {
    if (!promoTimer) {
      dispatch(getPromocodeList(amount, token))
    }
  }, [promoTimer])

  const debounceFunction = (func, delay) => {
    let timer;
    return function() {
      let self = this;
      let args= arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(self, args)
      }, delay)
    }
  }

  const debounceDropDown = useCallback(debounceFunction((nextValue) => dispatch(getPromocodeList(nextValue, token)), 1000), [])

  function handleAmount(e) {
    if (!e.target.value) {
      setAmount('')
      return setPromocodeList([])
    }
    debounceDropDown(e.target.value);
    setPromocodeList(promoList)
    setAmount(e.target.value)
    if (e.target.value != 0) {
      setDisableDeposit(true)
    } else {
      setDisableDeposit(false)
    }
  }

  function handleApplyPromocode(promo) {
    setPromoArray(promo)
    setPromoID(promo?._id)
    setSwithToRemove((swithToRemove) => !swithToRemove)
  }

  useEffect(() => {
    if (swithToRemove === false) {
      setPromoID('')
    }
  }, [swithToRemove])

  function handleDepositCash(e) {
    e.preventDefault()
    // if (+amount >= +minAmount) {
    if (+amount) {
      setModal(true)
      setLoading(true)
      dispatch(depositCash(amount, promoID, token))
      if (!resStatusDeposit && !depositStatus) {
        setModal(false)
        setLoading(false)
        setAmount('')
        if (depositMessage?.length && depositMessage !== 'success') {
          toast(
            <div>
              <div>{depositMessage}</div>
            </div>
          )
        }
      }
    }
  }

  function handleFixedAmount(Fixedamount) {
    setAmount(Fixedamount)
    setDisableDeposit(true)
    setSwithToRemove(false)
  }

  return (
    <>
      {loading && <Loading />}
      <div className="main-content half-padding deposit-cash">
        <div className="container">
          <h3 className="text-center account-title">Deposit Cash</h3>
          <div className="row">
            <div className="offset-md-1 col-md-10 offset-lg-1 col-lg-10 offset-xxl-2 col-xxl-8">
              <div className="table-cnt-box bg-blur br-6">
                <div className="text-center">
                  <p>
                    Deposit Range:
                    <span className="fw-bold">
                      &#8377;{minAmount} - &#8377;{maxAmount}
                    </span>
                  </p>
                </div>
                <form onSubmit={handleDepositCash}>
                  <div className="d-flex deposit-amount align-items-center">
                    <div className="form-group mb-0">
                      <input type="number" placeholder="₹ Enter Amount" value={amount} onChange={handleAmount} />
                    </div>
                    <div className="form-group m-0">
                      <button type="submit" disabled={!disableDeposit} className="common-btn yellow-btn small-btn">
                        ADD CASH
                      </button>
                    </div>
                  </div>
                  <div className="promocodes">
                    <ul className="m-0 list-unstyled">
                        {promocodeList?.map((promo, i) => (
                          promo.nExpireInHours !== null ? <li className="d-flex align-items-center justify-content-between" key={i}>
                            <p className="medium-text promo-desc d-flex flex-column gap-1">
                              <span>
                                {promo.sDescription?.length > 144 ? promo.sDescription.slice(0, 144) + '...' : promo.sDescription}
                              </span>
                              {promoTimer && promo?.nRemainingTime && <Timer promo={promo} setPromoTimer={setPromoTimer} />}
                            </p>
                            <p className="medium-text fw-bold text-uppercase">{promo.sCode}</p>
                            <button type="button" onClick={() => handleApplyPromocode(promo)} className="common-btn yellow-btn small-btn">
                              {swithToRemove && promoArray?._id === promo?._id ? 'remove' : 'apply'}
                            </button>
                          </li> : ''
                        ))}
                    </ul>
                  </div>
                  <Button color="primary" onClick={toggle} className="p-0 bg-transparent border-0 common-link">
                    View Promocodes
                  </Button>
                  <Collapse isOpen={isOpenPromo}>
                    <div className="promocodes">
                      <p className="medium-text">{promocodeList?.length ? 'Promocodes' : 'No Promocodes Available'}</p>
                      <ul className="m-0 list-unstyled">
                        {promocodeList?.map((promo, i) => (
                          promo.nExpireInHours === null ? <li className="d-flex align-items-center justify-content-between" key={i}>
                            <p className="medium-text promo-desc d-flex flex-column gap-1">
                              <span>
                                {promo.sDescription?.length > 144 ? promo.sDescription.slice(0, 144) + '...' : promo.sDescription}
                              </span>
                              {promoTimer && promo?.nRemainingTime && <Timer promo={promo} setPromoTimer={setPromoTimer} />}
                            </p>
                            <p className="medium-text fw-bold text-uppercase">{promo.sCode}</p>
                            <button type="button" onClick={() => handleApplyPromocode(promo)} className="common-btn yellow-btn small-btn">
                              {swithToRemove && promoArray?._id === promo?._id ? 'remove' : 'apply'}
                            </button>
                          </li> : ''
                        ))}
                      </ul>
                    </div>
                  </Collapse>
                  <ul className="p-0 list-unstyled suggest-amount d-flex flex-wrap">
                    <li>
                      <button type="button" onClick={() => handleFixedAmount(minAmount)}>
                        &#8377; {minAmount}
                      </button>
                    </li>
                    <li>
                      <button type="button" onClick={() => handleFixedAmount(30)}>
                        &#8377; 30
                      </button>
                    </li>
                    <li>
                      <button type="button" onClick={() => handleFixedAmount(60)}>
                        &#8377; 60
                      </button>
                    </li>
                    <li>
                      <button type="button" onClick={() => handleFixedAmount(90)}>
                        &#8377; 90
                      </button>
                    </li>
                    <li>
                      <button type="button" onClick={() => handleFixedAmount(maxAmount)}>
                        &#8377; {maxAmount}
                      </button>
                    </li>
                  </ul>
                </form>
                <p className="deposit-term gray-text">
                  By depositing, you agree to our{' '}
                  <a target="_blank" href="https://www.rummy24.com/terms-condition" rel="noreferrer" className="common-link">
                    {' '}
                    T&C
                  </a>{' '}
                  and{' '}
                  <a target="_blank" href="https://www.rummy24.com/privacy-policy" rel="noreferrer" className="common-link">
                    Privacy Policy
                  </a>
                  . We do not accept deposits from &nbsp;
                  {restrictedStates?.map((state, i) => (
                    <span key={i}>
                      {i === restrictedStates?.length - 1 ? (
                        <span>
                          <strong>{state}.</strong>
                        </span>
                      ) : (
                        <span>
                          <strong>{state}</strong>,&nbsp;
                        </span>
                      )}
                    </span>
                  ))}
                  You must be 18+ to play real money Rummy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggleModal} className="payment-modal">
        <ModalHeader toggle={toggleModal}></ModalHeader>
        <ModalBody style={{ width: '100%' }}>
          <div ref={modalRef}></div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default connect()(DepositCash)
