import React, { useEffect, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { toast, ToastContainer } from 'react-toastify'
import { Router } from 'react-router-dom'
import Routes from './routes/index'
import reducers from './reducers/index'
import Socket from './Socket'

export const history = createBrowserHistory({ basename: '/webgame' })

const componentEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(reducers, {}, componentEnhancers(applyMiddleware(ReduxThunk)))

import firebase from 'firebase'
import { FirebaseToken } from './actions/auth'
const config = {
  apiKey: 'AIzaSyCxKaxGKwS3_27dKDidYzYSrMTBq5MGq6M',
  authDomain: 'rummy24-ad18e.firebaseapp.com',
  databaseURL: 'https://rummy24-ad18e.firebaseio.com',
  projectId: 'rummy24-ad18e',
  storageBucket: 'rummy24-ad18e.appspot.com',
  messagingSenderId: '442234198953',
  appId: '1:442234198953:web:e729d98b3a937969e642b8',
  measurementId: 'G-M64QQE1WSB',
}

firebase.initializeApp(config)
firebase.analytics()
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging()
  messaging.onMessage((payload) => {
    toast(
      <div>
        <div> {payload.notification?.title}</div>
        <div> {payload.notification?.body}</div>
      </div>,
      { autoClose: 3000 }
    )
  })

  messaging
    .getToken()
    .then(function (token) {
      store.dispatch(FirebaseToken(token))
    })
    .catch(function (err) {
      console.error(err)
    })
}

function App() {
  console.error = () => {}
  return (
    <div>
      <Provider store={store}>
        <Router history={history}>
          <Routes />
          <Socket />
        </Router>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

const token = localStorage.getItem('Token')

if (token) {
  store.dispatch({
    type: 'TOKEN_LOGIN',
    payload: {
      token,
    },
  })
  if (history.location.pathname === '/') {
    history.push('/lobby')
  } else {
    history.push({ pathname: history.location.pathname, search: history.location.search })
  }
} else {
  history.push({ pathname: history.location.pathname })
}

export default App
