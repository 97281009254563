import { React, useState, useEffect } from 'react'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getGameTrasactions } from '../../../actions/gameTrasactions'
import { Link } from 'react-router-dom'
import Loading from '../../../components/Loading'
import Paginate from '../../../components/Paginate'
import Select from 'react-select'

function GameTransaction() {
  const [gameTransactions, setGameTransactions] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [firstPage, setFirstPage] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const gameTrasactionsData = useSelector((state) => state.gameTrasactions.gameTrasactionsData)
  // pagination
  const page = [
    { value: 10, label: 'Show 10' },
    { value: 25, label: 'Show 25' },
    { value: 40, label: 'Show 40' },
  ]

  useEffect(() => {
    document.title = 'Rummy24 | My Account'
  }, [])

  useEffect(() => {
    dispatch(getGameTrasactions(pageSize, currentPage, token))
    setLoading(true)
  }, [currentPage, pageSize])

  useEffect(() => {
    if (gameTrasactionsData?.transactions?.length) {
      setGameTransactions(gameTrasactionsData?.transactions)
      setPageCount(Math.ceil(gameTrasactionsData?.count[0]?.totalData / pageSize))
      setTotalData(gameTrasactionsData?.count[0]?.totalData)
      setLoading(false)
    } else if (gameTrasactionsData) {
      setLoading(false)
    }
  }, [gameTrasactionsData])

  function dateFormat(date) {
    return moment(date).format('MMM DD yyyy hh:mm A')
  }

  const handlePageClick = async (data) => {
    setCurrentPage(data.selected)
  }

  const handlePageSize = (e) => {
    if (Number(e.value) > 10) {
      setCurrentPage(0)
      setFirstPage(true)
    }
    setPageSize(Number(e.value))
    setPageCount(Math.ceil(totalData / e.value))
  }

  return (
    <>
      {loading && <Loading />}
      <div className="account-cnt sidebar-gap-color white-bg">
        <div className="game-transaction-section">
          <h3 className="text-center account-title">Game Transactions</h3>
          {gameTransactions?.length ? (
            <div className="table-cnt table-cnt-box br-6">
              <div className="table-responsive">
                <table className="common-table table">
                  <thead>
                    <tr>
                      <th className="text-center">Description</th>
                      <th className="text-center">Amount Debited</th>
                      <th className="text-center">Amount Credited</th>
                      <th className="text-center">Bonus Debited</th>
                      <th className="text-center">Bonus Credited</th>
                      <th className="text-center">Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gameTransactions.map((gameTransaction, i) => (
                      <tr key={i}>
                        <td className="text-center">
                          <Link className="common-link" to={`/game-transaction/table-transaction/${gameTransaction?.iTableId}`}>
                            {gameTransaction?.sDescription}
                          </Link>
                        </td>
                        <td className="text-center">&#8377; {gameTransaction?.nAmountIn?.toFixed(2) || 0}</td>
                        <td className="text-center">&#8377; {gameTransaction?.nAmountOut?.toFixed(2) || '0'}</td>
                        <td className="text-center">&#8377;{gameTransaction?.nBonusIn?.toFixed(2) || '0'}</td>
                        <td className="text-center">&#8377;{gameTransaction?.nBonusOut?.toFixed(2) || '0'}</td>
                        <td className="text-center">
                          {gameTransaction?.dCreatedDate ? dateFormat(gameTransaction?.dCreatedDate) : 'Not Available'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination-section d-flex justify-content-between">
                <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                <Select
                  options={page}
                  onChange={(e) => handlePageSize(e)}
                  value={page?.filter(function (page) {
                    return page.value == pageSize
                  })}
                  classNamePrefix="pagination-select"
                />
              </div>
            </div>
          ) : (
            <div className="table-cnt table-cnt-box br-6">
              <div className="table-responsive">
                <table className="common-table table">
                  <thead>
                    <tr>
                      <th className="text-center">Description</th>
                      <th className="text-center">Amount Debited</th>
                      <th className="text-center">Amount Credited</th>
                      <th className="text-center">Bonus Debited</th>
                      <th className="text-center">Bonus Credited</th>
                      <th className="text-center">Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="6" className="text-center">
                        Not Available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default connect()(GameTransaction)
