import React, { useMemo } from 'react'
import Countdown from 'react-countdown'
import PropTypes from 'prop-types'

export default function Timer({ promo, setPromoTimer }) {
  const scheduledTime = useMemo(() => new Date(Date.now() + promo?.nRemainingTime).toUTCString(), [promo?.nRemainingTime])
  return (
    <span style={{ color: '#3752de' }}>
      Remaining Time : {''}
      <b>
        <Countdown daysInHours={true} date={scheduledTime} onComplete={() => setPromoTimer(false)}></Countdown>
      </b>
    </span>
  )
}

Timer.propTypes = {
  promo: PropTypes.object,
  setPromoTimer: PropTypes.func,
}
