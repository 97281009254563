
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client'
import { toast } from 'react-toastify';
import config from './config'
import { unAuthorized } from './helper'
import { takeSeat } from './actions/tournament';
import { getMaintainanace } from './actions/lobby';

function Socket() {

    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const joinedTablesData = useSelector(state => state.lobby.joinedTablesData)
    const value = localStorage.getItem('logout')

    useEffect(() => {
        if (value === "toast") {
            toast(
                <div>
                    <div>Authentication Error, Please try logging again</div>
                </div>
                , { autoClose: 4000 })
        }
    }, [value]);

    useEffect(() => {
        let socket = null
        if (token) {
            socket = io(config.SOCKET_URL, {
                transports: ['websocket', 'polling'],
                query: {
                    authorization: token,
                },
            });
            socket.on('connect', () => {
            });

            socket.on('resTournamentGoingToStart', (data) => {
                toast(
                    <div>
                        <div>{data?.sMessage}</div>
                    </div>
                    , { autoClose: 10000 });
            })

            socket.on('resTakeTournamentSeat', (data) => {
                dispatch(takeSeat(data))
            })

            socket.on('resMaintenance', (data) => {
                dispatch(getMaintainanace(data))
            })

            // socket.on('resQualifier', (data) => {
            // })
            // socket.on('resLogout', (data) => {
            //     if (joinedTablesData?.length) {
            //     } else {
            //         toast(
            //             <div>
            //                 <div>{data?.message}</div>
            //             </div>
            //         );
            //         unAuthorized()
            //         socket ? socket.disconnect() : null
            //     }
            // })
        } else {
            socket ? socket.disconnect() : null
        }
        return () => socket ? socket.disconnect() : null
    }, [token])

    return (
        <>
        </>
    )
}

export default Socket
