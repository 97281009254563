import { React, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { getOffers } from '../../actions/offers';
import Loading from '../../components/Loading';

function offers() {

    const [loading, setLoading] = useState(false);
    const [offersList, setOffersList] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [promoId, setPromoId] = useState('');
    const [promoDescription, setPromoDescription] = useState('');

    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const offersData = useSelector(state => state.offers.data)

    useEffect(() => {
        setLoading(true)
        dispatch(getOffers(token))
        document.title = 'Rummy 24 | Offers'
    }, [])

    useEffect(() => {
        if (offersData) {
            setLoading(false)
            setOffersList(offersData)
        }
    }, [offersData])

    function handleDescription(offer) {
        setShowMore(!showMore)
        setPromoId(offer?._id)
        setPromoDescription(offer?.oPromoCode?.sDescription)
    }

    return (
        <div>
            {loading && <Loading />}
            <div className="main-content half-padding offers-section">
                <div className="container">
                    <h3 className="text-center account-title">Offers</h3>
                    <div className="row">
                        {!!offersList?.length && offersList.map((offer, i) => (
                            <div className="col-md-4 text-center" key={i}>
                                <img src={offer?.sIcon} alt="offer-banner" />
                                <p>Promo Code:<span className="fw-bold">{offer?.oPromoCode?.sCode || "NA"}</span></p>
                                <button type="button" className='common-btn small-btn' onClick={() => handleDescription(offer)}>View More</button>
                                {(promoId == offer?._id) && <p>{promoDescription || 'NA'}</p>}
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect()(offers);
