import constants from '../constants'

export default (state = {}, action) => {
    switch (action.type) {
        case constants.GET_CLUBLIST:
            return {
                ...state,
                data: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.GET_CLUB_GAMES:
            return {
                ...state,
                clubGameData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.JOIN_CLUB_TABLE:
            return {
                ...state,
                joinClubTableData: action.payload.data,
                resStatusJoinClubTable: action.payload.resStatus,
                resMessageJoinClubTable: action.payload.resMessage
            }
        case constants.CLUB_INFO:
            return {
                ...state,
                clubInfoData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.GET_CLUB_BALANCE:
            return {
                ...state,
                clubBalanceData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.JOIN_CLUB:
            return {
                ...state,
                resStatusJoinClub: action.payload.resStatus,
                resMessageJoinClub: action.payload.resMessage
            }
        case constants.GET_CLUB_REQUEST_LIST:
            return {
                ...state,
                clubRequestListData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.LEAVE_CLUB:
            return {
                ...state,
                resStatusLeaveClub: action.payload.resStatus,
                resMessageJoinClub: action.payload.resMessage
            }
        case constants.CLUB_WITHDRAW:
            return {
                ...state,
                resStatusWithdraw: action.payload.resStatus,
                resMessageJoinClub: action.payload.resMessage
            }
        case constants.GET_CLUB_WITHDRAW_LIST:
            return {
                ...state,
                clubWithdrawList: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.CANCEL_CLUB_WITHDRAW:
            return {
                ...state,
                resStatusCancelClubWithdraw: action.payload.resStatus,
                resMessageJoinClub: action.payload.resMessage
            }
        case constants.CLUB_GAME_TRANSACTION:
            return {
                ...state,
                clubGameTransaction: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.CLUB_TABLE_TRANSACTION:
            return {
                ...state,
                clubTableTransaction: action.payload.data[0],
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.CLUB_PASSBOOK:
            return {
                ...state,
                clubPassbookTransaction: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.CLUB_DEPOSIT:
            return {
                ...state,
                resStatusClubDeposit: action.payload.resStatus,
                resMessageJoinClub: action.payload.resMessage
            }
        case constants.GET_CLUB_DEPOSIT_LIST:
            return {
                ...state,
                clubDepositList: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.CANCEL_CLUB_DEPOSIT:
            return {
                ...state,
                resStatusCancelClubDeposit: action.payload.resStatus,
                resMessageJoinClub: action.payload.resMessage
            }
        case constants.LEAVE_CLUB_FROM_INFO:
            return {
                ...state,
                resStatusLeaveClubFromInfo: action.payload.resStatus,
                resMessageJoinClub: action.payload.resMessage
            }
        case constants.CLEAR_CLUB:
            return {
                ...state,
                data: null,
                // clubGameData: null,
                clubInfoData: null,
                joinClubTableData: null,
                resStatus: null,
                clubRequestListData: null,
                resMessage: '',
                clubGameTransaction: null,
                clubPassbookTransaction: null,
                clubTableTransaction: null,
                clubDepositList: null,
            }
        default:
            return state
    }
}