import { React } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.svg';
import amazon from '../assets/images/amazon.png';
import cash from '../assets/images/cash.png';
import free from '../assets/images/free.png';
import phonepay from '../assets/images/phonepay.png';
import android from '../assets/images/android.png';
import apple from '../assets/images/apple.png';

import fb from '../assets/images/fb-icon.svg';




function Footer () {
    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-xs-12">
                        <div className='footer-l1'>
                            <div className="text-center ">
                                <Link to="/lobby"><img src={logo} alt="logo" className='logo' /></Link>
                                <p className="text-center ftr-txt">
                                    Rummy24 is the ultimate rummy platform for playing all
                                    the variants of rummy cards games online. Experience
                                    the thrill of gaming in the unique, enhanced gaming
                                    environment at Rummy24 online app tables with cool
                                    avatars, and challenge players from across the country.
                                    We offer you amazing opportunities to use your skills,
                                    play rummy and earn real money with other exciting prizes.
                                </p>
                            </div>
                            <p className="footer-txt text-center mt-0 ">Social</p>
                            {/* <ul className="icon-wrap">
                                <li><a href="https://www.facebook.com/rummy24/" ><span className='fab'><img src={fb} alt='fb' /> </span></a></li>
                                <li><a href="https://twitter.com/Rummy_24">  </a></li>
                                <li><a href="https://www.instagram.com/rummy.24/"> <i className="fa fa-instagram inst" aria-hidden="true"></i> </a></li>
                                <li><a href="https://www.youtube.com/channel/UCsgKxMpSShou36jxHPW0fRA"> <i className="fa fa-youtube-play you" aria-hidden="true"></i> </a></li>
                            </ul> */}
                            <ul className="social-icon d-flex">
                                <li className="fb"><a href="https://www.facebook.com/rummy24/" to="https://www.facebook.com/rummy24/" rel="noreferrer" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li className="tw"><a to="https://twitter.com/Rummy_24" target="_blank" href="https://twitter.com/Rummy_24" rel="noreferrer"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li className="insta"><a to="https://www.instagram.com/rummy.24/" target="_blank" href="https://www.instagram.com/rummy.24/" rel="noreferrer"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                <li className="yt"><a to="https://www.youtube.com/channel/UCsgKxMpSShou36jxHPW0fRA" target="_blank" href="https://www.youtube.com/channel/UCsgKxMpSShou36jxHPW0fRA" rel="noreferrer"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li></ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <div className="row">
                            <div className="col-md-1 col-sm-4 ">
                            </div>
                            <div className="col-md-5 col-sm-4 ">
                                <p className="footer-txt mb-10">Quick Links</p>
                                <ul className="list-unstyled">
                                    <li className=""><a href="https://www.rummy24.com/">Home </a></li>
                                    <li className=""><a href="https://www.rummy24.com/about">About Us </a></li>
                                    <li><a href="https://www.rummy24.com/how-to-play-rummy">How To Play </a></li>
                                    <li><a href="https://www.rummy24.com/contact">Contact Us </a></li>
                                    <li><a href="https://www.rummy24.com/refer-a-friend-offer">Refer A Friend Offer </a></li>
                                    <li><a href="https://blog.rummy24.com/">Blog </a></li>
                                    <li><a href="https://www.rummy24.com/download-mobile-rummy-app">Download App</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-sm-4 ">
                                <p className='txt-color footer-txt mb-10'>Quick Links</p>
                                <ul className="list-unstyled dwn">
                                    <li><a href="https://www.rummy24.com/terms-condition">Terms &amp; Condition</a></li>
                                    <li><a href="https://www.rummy24.com/responsible-play.php">Responsible Play</a></li>
                                    <li><a href="https://www.rummy24.com/privacy-policy">Privacy Policy </a></li>
                                    <li><a href="https://www.rummy24.com/cancellation-refund">Cancellation &amp; Refund Policy</a></li>
                                    <li><a href="https://www.rummy24.com/amazon-pay-gift-card">Amazon pay gift card </a></li>
                                    <li><a href="https://www.rummy24.com/legality">Legality </a></li>
                                    <li><a href="https://www.rummy24.com/faq">Faq </a></li>
                                    <li><a href="https://www.rummy24.com/disclaimer">Disclaimer </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <div className='footer-r1'>
                            <p className='footer-txt mb-15'>
                                Our Payment Partners
                            </p>
                            <div className="fotr-pymt">
                                <img src={amazon} alt="amazon" className="img-responsive" />
                                <img src={phonepay} alt="phonepay" className="img-responsive" />
                                <img src={free} alt="free charge" className="img-responsive" />
                                <img src={cash} alt="cashfree" className="img-responsive" />
                            </div>
                            <hr />
                            <p className="white-txt">
                                {/* style="color:#fff; padding-bottom:5px;" */}
                                India&apos;s most trusted now
                            </p>
                            <div className='d-flex flex-wrap download-box'>
                                <a href="https://rummy24.sng.link/Bverc/upes/koic" target="_blank" rel='noreferrer'>
                                    <img src={android} alt="download our android app" className='img-responsive' />
                                    {/* style="width:180px; margin:3px;" */}
                                </a>
                                <a href="https://apps.apple.com/in/app/rummy24/id1497685003" target="_blank" rel='noreferrer'>
                                    <img src={apple} alt="Download on the App Store" className='img-responsive' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect()(Footer);
